import { Fragment, useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import Select from "react-select";
import moment from "moment";
import "moment-timezone";
import {
  actividadesEmpresa,
  obtenerAdjuntos,
  downloadFile,
} from "../../api/actividades";
import { obtenerUsuariosDispositivos, obtenerEmpresa } from "../../api/usuario";
import Spinner from "../../components/spinner";
import Grid from "../../components/grid";

export default function Actividades() {
  const [loading, setLoading] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [verAdjunto, setVerAdjunto] = useState(false);
  const [actividades, setActividades] = useState([]);
  const [actividad, setActividad] = useState(null);
  const [configAdjuntos, setConfigAdjuntos] = useState(null);
  const [listaAdjuntos, setListaAdjuntos] = useState([]);
  const [nombreArchivo, setNombreArchivo] = useState(null);
  const [blobArchivo, setBlobArchivo] = useState(null);
  const cancelButtonRef = useRef(null);
  const history = useHistory();

  const headersActividades = [
    { key: "acciones", title: "Acciones", id: 1 },

    { key: "nombre", title: "Asignado a", filter: true },
    { key: "email", title: "Email", filter: true },

    { key: "codigoInterno", title: "Cod. Interno", filter: true },
    { key: "descripcion", title: "Descripcion", filter: true },
    { key: "fechaFormat", title: "Fecha Realizar", filter: true },

    { key: "estado", title: "Estado", filter: true },

    { key: "grupo", title: "Grupo", filter: true },
    { key: "subgrupo", title: "subgrupo", filter: true },
  ];

  const agregarAcciones = (lista) => {
    try {
      if (lista.length > 0) {
        for (let i = 0; i < lista.length; i++) {
          let acciones = [];
          let objModificar = {
            label: "Modificar",
            color: "#00b894",
            onClick: (param) => {
              nextPage(`actividades/modificar/${param.idActividad}`);
            },
          };
          let objVer = {
            label: `Ver`,
            color: "#00b894",
            onClick: (param) => {
              console.log(param);
              setListaAdjuntos([]);
              setVerAdjunto(false);
              setActividad(param);
              setOpenModalInfo(true);
            },
          };
          acciones.push(objVer);
          acciones.push(objModificar);
          lista[i].acciones = acciones;
        }
        setActividades(lista);
      }
    } catch (err) {
      console.log("agregarAccionesALista err");
      console.log(err);
    }
  };

  const nextPage = (page) => {
    try {
      history.push(`${page}`);
    } catch (err) {
      console.log("nextPage err");
      console.log(err);
    }
  };
  const formatearHoraBolivia = (val) => {
    return moment.utc(val).tz("America/La_Paz").format("YYYY-MM-DD HH:mm:ss");
  };
  const obtenerActividades = async () => {
    const resp = await actividadesEmpresa();
    const usuarios = await obtenerUsuariosDispositivos();
    console.log(usuarios);
    const nullValues = {
      id: null,
      nombre: null,
      cel: null,
    };
    const newArray = resp.body.body.map((item) => {
      const matchingItem = usuarios.body.find(
        (obj) => obj.id === item.idUsuarioDispositivo
      );
      return matchingItem
        ? { ...item, ...matchingItem, idActividad: item.id }
        : { ...item, ...nullValues, idActividad: item.id };
    });
    console.log(newArray);
    const newArrayformat = newArray
      .map((val) => {
        return {
          ...val,
          fechaFormat: val.fechaRealizar.split("T")[0],
        };
      })
      .sort((a, b) => {
        const fechaA = new Date(a.fechaFormat);
        const fechaB = new Date(b.fechaFormat);
        return fechaB - fechaA;
      });

    console.log(newArrayformat);
    //setActividades(newArray);
    agregarAcciones(newArrayformat);
    console.log(resp);
  };
  const obtenerAttachement = async () => {
    //host, user, password, codInterno, name
    setLoading(true);
    const data = { ...configAdjuntos, codInterno: actividad.codigoInterno };
    const resp = await obtenerAdjuntos(data);
    setListaAdjuntos(resp.body);
    setLoading(false);
    console.log(resp);
  };
  const obtenerConfig = async () => {
    const resp = await obtenerEmpresa();
    const {
      urlServidorArchivo: host,
      usuarioServidorArchivo: user,
      contracenaServidorArchivo: password,
    } = resp.body.body[0];
    setConfigAdjuntos({ host, user, password });
  };

  const obtenerBufferDeAdjunto = async () => {
    const data = {
      ...configAdjuntos,
      codInterno: actividad.codigoInterno,
      name: nombreArchivo,
    };
    setLoading(true);
    const resp = await downloadFile(data);

    setBlobArchivo(URL.createObjectURL(resp));
    setLoading(false);
  };
  useEffect(() => {
    obtenerActividades();
    obtenerConfig();
  }, []);

  useEffect(() => {
    if (actividad) {
      obtenerAttachement();
    }
  }, [actividad]);
  useEffect(() => {
    if (nombreArchivo) {
      obtenerBufferDeAdjunto();
      setVerAdjunto(true);
    }
  }, [nombreArchivo]);
  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-5">
          <h3 className="mx-5 text-lg font-medium leading-6 text-gray-900">
            Actividades
          </h3>
          <p className="mx-5 mt-1 max-w-2xl text-sm text-gray-500">
            Listado de las actividades a creadas.
          </p>
        </div>

        <Grid
          rows={actividades}
          headers={headersActividades}
          //filaClick={filaClick}
          //center={true}
        />
      </div>
      <Transition.Root show={openModalInfo} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenModalInfo}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl min-h-[630px] h-[65vh] w-[60vw] transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  {verAdjunto === false ? (
                    <>
                      <div>
                        <div className="mt-3 text-center h-auto sm:mt-5">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            Informacion
                          </Dialog.Title>
                          <div className="mt-2">
                            <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                              <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                    <div className="sm:col-span-1">
                                      <dt className="text-sm font-medium text-gray-500">
                                        Nombre
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900">
                                        {actividad ? actividad.nombre : "-"}
                                      </dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <dt className="text-sm font-medium text-gray-500">
                                        Celular/Telefono
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900">
                                        {actividad ? actividad.cel : "-"}
                                      </dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <dt className="text-sm font-medium text-gray-500">
                                        Email address
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900">
                                        {actividad ? actividad.email : "-"}
                                      </dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <dt className="text-sm font-medium text-gray-500">
                                        Tipo de actividad
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900">
                                        {actividad ? actividad.tipo : "-"}
                                      </dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <dt className="text-sm font-medium text-gray-500">
                                        Fecha de creacion
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900">
                                        {actividad
                                          ? moment(
                                              actividad.fechaHoraCreacion
                                            ).format("YYYY-MM-DD HH:mm:ss")
                                          : "-"}
                                      </dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <dt className="text-sm font-medium text-gray-500">
                                        Fecha a realizar
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900">
                                        {actividad
                                          ? moment(
                                              actividad.fechaRealizar
                                            ).format("YYYY-MM-DD")
                                          : "-"}
                                      </dd>
                                    </div>
                                    <div className="sm:col-span-2">
                                      <dt className="text-sm font-medium text-gray-500">
                                        Descripcion
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900">
                                        {actividad
                                          ? actividad.descripcion
                                          : "-"}
                                      </dd>
                                    </div>
                                    <div className="sm:col-span-2">
                                      <dt className="text-sm font-medium text-gray-500">
                                        Attachments
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900">
                                        <ul
                                          role="list"
                                          className="divide-y divide-gray-200 rounded-md border border-gray-200 overflow-auto max-h-[90px]"
                                        >
                                          {loading ? (
                                            <div className="my-[5px]  flex">
                                              <div className="m-auto">
                                                <Spinner />
                                              </div>
                                            </div>
                                          ) : (
                                            listaAdjuntos.map((data) => (
                                              <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                                <div className="flex w-0 flex-1 items-center">
                                                  <PaperClipIcon
                                                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                                                    aria-hidden="true"
                                                  />
                                                  <span className="ml-2 w-0 flex-1 truncate">
                                                    {data.name}
                                                  </span>
                                                </div>
                                                <div className="ml-4 flex-shrink-0">
                                                  <a
                                                    href="#"
                                                    className="font-medium text-indigo-600 hover:text-indigo-500"
                                                  >
                                                    Descargar
                                                  </a>
                                                </div>
                                                <div className="ml-4 flex-shrink-0">
                                                  <a
                                                    href="#"
                                                    className="font-medium text-indigo-600 hover:text-indigo-500"
                                                    onClick={() => {
                                                      setBlobArchivo(null);
                                                      setNombreArchivo(
                                                        data.name
                                                      );
                                                    }}
                                                  >
                                                    Ver
                                                  </a>
                                                </div>
                                              </li>
                                            ))
                                          )}
                                        </ul>
                                      </dd>
                                    </div>
                                  </dl>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                          onClick={() => setOpenModalInfo(false)}
                        >
                          Aceptar
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                          onClick={() => setOpenModalInfo(false)}
                          ref={cancelButtonRef}
                        >
                          Cancelar
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="bg-white">
                        <div className="pt-6 pb-16 sm:pb-24 h-[55vh]">
                          <h2 className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            Archivo adjunto:
                          </h2>
                          <div className="mx-auto mt-8 max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                            <div className="lg:grid lg:auto-rows-min lg:grid-cols-12 lg:gap-x-8">
                              <div className="lg:col-span-5 lg:col-start-8">
                                <div className="block">
                                  <h1 className="text-md font-medium text-gray-900">
                                    {nombreArchivo}
                                  </h1>
                                </div>
                              </div>

                              <div className="mt-8 lg:col-span-7 lg:col-start-1 lg:row-span-3 lg:row-start-1 lg:mt-0">
                                <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-1 lg:gap-8">
                                  {loading ? (
                                    <div className="h-[300px]  flex">
                                      <div className="m-auto">
                                        <Spinner />
                                      </div>
                                    </div>
                                  ) : (
                                    <img
                                      key="1"
                                      src={blobArchivo}
                                      alt=""
                                      className={
                                        "lg:col-span-2 lg:row-span-2 h-auto"
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-6 flex">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center m-auto rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                          onClick={() => setVerAdjunto(false)}
                        >
                          Atras
                        </button>
                      </div>
                    </>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
