import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ChevronRightIcon,
  Squares2X2Icon,
  DevicePhoneMobileIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import { MarkerWithLabel } from "@googlemaps/markerwithlabel";
import { eventoDispositivosEmpresa } from "../api/rastreo";
import { actividadesDispositivo } from "../api/actividades";

export default function Rastreo() {
  const [hiddenGrid, setHiddenGrid] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [openModalInfoActividad, setOpenModalInfoActividad] = useState(false);
  const [map, setMap] = useState(null);
  const [google, setGoogle] = useState(null);
  const [markerWithLabel, setMarkerWithLabel] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [loadingMap, setLoadingMap] = useState(false);
  const [dispositivosData, setDispositivosData] = useState([]);
  const [dispositivosDataAnidado, setDispositivosDataAnidado] = useState([]);
  const [dispositivoData, setDispositivoData] = useState(null);
  const [actividades, setActividades] = useState([]);
  const [actividad, setActividad] = useState(null);

  const [groupCheckboxes, setGroupCheckboxes] = useState({});
  const [subgroupCheckboxes, setSubgroupCheckboxes] = useState({});

  const cancelButtonRef = useRef(null);
  const cancelButtonActividadRef = useRef(null);

  const mapInit = () => {
    console.log("init");
    var boltracksrl = { lat: -17.759, lng: -63.194 };
    let mapNew = new google.maps.Map(
      document.getElementById("gmapsCanvasContainer"),
      {
        zoom: 8,
        center: boltracksrl,
        zoomControl: true,
        mapTypeControl: false,
        mapTypeControlOptions: {
          mapTypeIds: ["hybrid", "roadmap", "satellite", "terrain"],
          position: google.maps.ControlPosition.TOP_RIGHT,
        },
        streetViewControl: true,
        fullScreenControl: true,
        scrollwheel: true,
        noClear: true,
      }
    );
    setMap(mapNew);
  };
  const calcularTimeDifference = (value) => {
    const currentDate = moment();
    const targetDate = moment(value);
    const differenceInMinutes = currentDate.diff(targetDate, "minutes");
    const differenceInDays = currentDate.diff(targetDate, "days");
    let difference;
    console.log(differenceInMinutes);
    console.log(differenceInDays);
    if (differenceInMinutes < 60) {
      difference = `${differenceInMinutes} minutos`;
    } else {
      if (differenceInMinutes < 1440) {
        difference = `${Math.trunc(differenceInMinutes / 60)} horas`;
      } else {
        difference = `${differenceInDays} dias`;
      }
    }
    return difference;
  };
  const handleCheckboxClick = (id) => {
    console.log(markers[id]);
    markers[id].marker.setVisible(!markers[id].marker.visible);
  };

  const handleGroupCheckboxClick = (grupo) => {
    console.log(markers);
    Object.values(markers).forEach((markerData) => {
      if (markerData.grupo === grupo) {
        markerData.marker.setVisible(!markerData.marker.visible);
      }
    });
  };
  const handleSubgroupCheckboxClick = (subgrupo) => {
    Object.values(markers).forEach((markerData) => {
      if (markerData.subgrupo === subgrupo) {
        markerData.marker.setVisible(!markerData.marker.visible);
      }
    });
  };
  const actualizarDispositivos = async () => {
    const resp = await eventoDispositivosEmpresa();
    setDispositivosData(resp.body);
    const dispositivosAnidados = resp.body.reduce((acumulador, dispositivo) => {
      const { grupo, subgrupo } = dispositivo;

      if (!acumulador[grupo]) {
        acumulador[grupo] = {};
      }

      if (!acumulador[grupo][subgrupo]) {
        acumulador[grupo][subgrupo] = [];
      }

      acumulador[grupo][subgrupo].push(dispositivo);

      return acumulador;
    }, {});

    setDispositivosDataAnidado(dispositivosAnidados);

    // Actualizar las coordenadas de los marcadores existentes
    const updatedMarkers = { ...markers };
    for (let x = 0; x < resp.body.length; x++) {
      if (updatedMarkers[resp.body[x].codigoInterno]) {
        updatedMarkers[resp.body[x].codigoInterno].marker.setPosition(
          new google.maps.LatLng(resp.body[x].lat, resp.body[x].lng)
        );
      }
    }

    setMarkers(updatedMarkers);
  };

  const obtenerDispositivos = async () => {
    const resp = await eventoDispositivosEmpresa();
    setDispositivosData(resp.body);
    const dispositivosAnidados = resp.body.reduce((acumulador, dispositivo) => {
      const { grupo, subgrupo } = dispositivo;

      if (!acumulador[grupo]) {
        acumulador[grupo] = {};
      }

      if (!acumulador[grupo][subgrupo]) {
        acumulador[grupo][subgrupo] = [];
      }

      acumulador[grupo][subgrupo].push(dispositivo);

      return acumulador;
    }, {});
    setDispositivosDataAnidado(dispositivosAnidados);
    let markerList = {};
    const groupCheckboxDefaultState = {};
    const subgroupCheckboxDefaultState = {};
    console.log(dispositivosAnidados);
    setGroupCheckboxes(groupCheckboxDefaultState);
    setSubgroupCheckboxes(subgroupCheckboxDefaultState);
    console.log(map);
    for (let x = 0; x < resp.body.length; x++) {
      markerList[resp.body[x].codigoInterno] = {
        marker: null,
        grupo: resp.body[x].grupo,
        subgrupo: resp.body[x].subgrupo,
      };
      markerList[resp.body[x].codigoInterno].marker = new MarkerWithLabel({
        position: new google.maps.LatLng(resp.body[x].lat, resp.body[x].lng),
        icon: {
          url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
            `<svg width="28" height="28" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-7 h-7">
            <g>
             <title>Layer 1</title>
             <circle cx="14" cy="13.66666" r="12.3" fill="rgb(59 130 246)" id="svg_1" stroke="null"/>
             <path fill="white" d="m12.5,19.08333a0.75,0.75 0 0 0 0,1.5l3,0a0.75,0.75 0 0 0 0,-1.5l-3,0z" id="svg_2"/>
             <path fill="white" fill-rule="evenodd" d="m11.08333,4.66666a2.91667,2.8 0 0 0 -2.91667,2.8l0,13.06668a2.91667,2.8 0 0 0 2.91667,2.8l5.83334,0a2.91667,2.8 0 0 0 2.91667,-2.8l0,-13.06668a2.91667,2.8 0 0 0 -2.91667,-2.8l-5.83334,0zm-0.97222,2.8c0,-0.5152 0.43556,-0.93333 0.97222,-0.93333l0.97222,0l0,0.31111c0,0.5152 0.43556,0.93333 0.97222,0.93333l1.94445,0c0.53667,0 0.97222,-0.41813 0.97222,-0.93333l0,-0.31111l0.97222,0c0.53667,0 0.97222,0.41813 0.97222,0.93333l0,13.06668c0,0.5152 -0.43556,0.93333 -0.97222,0.93333l-5.83334,0a0.97222,0.93333 0 0 1 -0.97222,-0.93333l0,-13.06668l0.00001,0z" clip-rule="evenodd" id="svg_3" stroke="null"/>
            </g>
           
           </svg>`
          )}`,
          scaledSize: new google.maps.Size(30, 30),
        },
        clickable: true,

        map: map,
        labelContent: `<div style='color: white; background-color: #0F3457;border-radius: 5px; font-weight: 900;padding-left: 10px; padding-right: 10px;'>
          <p>${resp.body[x].nombre}</p>
           </div>`, // can also be HTMLElement
        labelAnchor: new google.maps.Point(-31, 3),
        labelClass: "labels", // the CSS class for the label
        labelStyle: { opacity: 1.0 },
      });
      markerList[resp.body[x].codigoInterno].marker.addListener("click", () => {
        console.log("entra al click");
        setDispositivoData(resp.body[x]);
        setOpenModalInfo(true);
        //infowindow.open(map, markerList[newArray[x].codigoInterno]);
      });
    }

    setMarkers(markerList);
    console.log(resp);
  };
  const obtenerActividades = async () => {
    const resp = await actividadesDispositivo(dispositivoData.id);
    setActividades(resp.body.resp.body);
    console.log(resp);
  };
  const centerAndZoomMap = (coordenadas) => {
    const center = new window.google.maps.LatLng(
      coordenadas.lat,
      coordenadas.lng
    );
    map.panTo(center);
    map.setZoom(14);
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/jsapi";
    script.onload = () => {
      setGoogle(window.google);
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useEffect(() => {
    if (google) {
      mapInit();
    } else {
    }
  }, [google]);

  useEffect(() => {
    if (map) {
      obtenerDispositivos();
    }
  }, [map]);
  useEffect(() => {
    if (dispositivoData) {
      obtenerActividades();
    }
  }, [dispositivoData]);
  useEffect(() => {
    if (markers) {
      const interval = setInterval(() => {
        actualizarDispositivos();
      }, 12000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [markers]);
  return (
    <>
      <div className="flex">
        <div
          className={`w-[30px] flex bg-[#091d30] h-screen ${
            !hiddenGrid ? "hidden" : "block"
          }`}
          onClick={() => {
            setHiddenGrid(false);
          }}
        >
          <ChevronRightIcon className="text-white m-auto" />
        </div>
        <div
          className={`min-w-[300px] w-[30vw] bg-[#091d30] h-screen p-[10px] ${
            hiddenGrid ? "hidden" : "block"
          }`}
        >
          <div className="pb-[5px] flex justify-center">
            <p className="text-white font-medium w-[90%] text-center">
              Seguimiento Online
            </p>
            <p
              className="text-white font-medium text-rigth"
              onClick={() => {
                setHiddenGrid(true);
              }}
            >
              X
            </p>
          </div>
          <div className="bg-[#113A5F] h-[97%] mb-[16px] w-full rounded-md p-[5px]">
            <div className="bg-[#091d30] h-auto pb-[5px] w-full rounded-md ">
              {Object.keys(dispositivosDataAnidado).map((dispositivosGrupo) => (
                <>
                  <div className="flex p-[5px] ">
                    <input
                      type="checkbox"
                      defaultChecked={true}
                      //checked={groupCheckboxes[dispositivosGrupo]}
                      className="h-4 w-4 my-auto rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 mr-[10px] ml-[5px]"
                      onChange={() => {
                        handleGroupCheckboxClick(dispositivosGrupo);
                      }}
                    />
                    <p className="text-white font-medium">
                      {dispositivosGrupo}
                    </p>
                  </div>
                  {Object.keys(dispositivosDataAnidado[dispositivosGrupo]).map(
                    (dispositivosSubgrupo) => (
                      <div
                        className="bg-[#113A5F] mx-[10px] my-[5px] rounded pb-[0.5px]"
                        style={{ height: "auto" }}
                      >
                        <div className="flex p-[5px]">
                          <input
                            /* checked={
                              subgroupCheckboxes[dispositivosGrupo][
                                dispositivosSubgrupo
                              ]
                            }*/
                            defaultChecked={true}
                            type="checkbox"
                            className="h-4 w-4 my-auto rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 mr-[10px] ml-[5px] "
                            onChange={() => {
                              handleSubgroupCheckboxClick(dispositivosSubgrupo);
                            }}
                          />
                          <p className="text-white font-medium">
                            {dispositivosSubgrupo}
                          </p>
                        </div>
                        <div className="bg-white mb-[15px]">
                          <div className="flex text-[11px] px-[5px] border-b-2 px-[15px]">
                            <p className="w-1/6 my-auto">Ver</p>
                            <p className="w-2/6 my-auto">Nombre</p>
                            <p className="w-1/4 my-auto">Estado</p>
                            <p className="w-1/4 my-auto leading-3">
                              Ult. Conexion
                            </p>
                          </div>
                          {dispositivosDataAnidado[dispositivosGrupo][
                            dispositivosSubgrupo
                          ].map((data) => (
                            <div className="flex text-[11px] px-[5px] border-b-[1px] px-[15px] cursor-pointer">
                              <div className="w-1/6 flex">
                                <input
                                  type="checkbox"
                                  defaultChecked={true}
                                  className=" h-3 w-3 my-auto mx-[2px] rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  onChange={() =>
                                    handleCheckboxClick(data.codigoInterno)
                                  }
                                />
                              </div>
                              <p
                                className="w-2/6 my-auto"
                                onClick={() => {
                                  //setDispositivoData(data);
                                  centerAndZoomMap({
                                    lat: data.lat,
                                    lng: data.lng,
                                  });
                                  //setOpenModalInfo(true);
                                }}
                              >
                                {data.nombre}
                              </p>
                              <div className="w-1/4 my-auto">
                                <div
                                  className="w-[10px] h-[10px] rounded-full"
                                  style={{
                                    backgroundColor: `${
                                      !calcularTimeDifference(
                                        data.fechaHora
                                      ).includes("dias")
                                        ? data.estado === "ON"
                                          ? "#22C55E"
                                          : "#EF4444"
                                        : "#EF4444"
                                    }`,
                                  }}
                                />
                              </div>
                              <p className="w-1/4 my-auto">
                                {calcularTimeDifference(data.fechaHora)}.
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
        {!loadingMap ? (
          <div
            id="gmapsCanvasContainer"
            style={{
              width: "100%",
              minHeight: "855px",
              height: "100vh",
              zIndex: 1,
            }}
          ></div>
        ) : (
          <div>Cargando...</div>
        )}
      </div>
      <Transition.Root show={openModalInfo} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenModalInfo}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl h-[65vh] w-[60vw] transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Informacion
                      </Dialog.Title>
                      <div className="mt-2">
                        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                          <div className="border-t border-gray-200">
                            <dl>
                              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Nombre
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {dispositivoData
                                    ? dispositivoData.nombreUsuario
                                    : "-"}
                                </dd>
                              </div>
                              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Correo Electronico
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {dispositivoData
                                    ? dispositivoData.email
                                    : "-"}
                                </dd>
                              </div>
                              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Celular/Telefono
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {dispositivoData ? dispositivoData.cel : "-"}
                                </dd>
                              </div>
                              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  IMEI
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {dispositivoData ? dispositivoData.imei : "-"}
                                </dd>
                              </div>
                              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Ultima Conexion
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {dispositivoData
                                    ? calcularTimeDifference(
                                        dispositivoData.fechaHora
                                      )
                                    : "-"}
                                </dd>
                              </div>
                              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Actividades
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 max-h-[95px] overflow-auto">
                                  <ul
                                    role="list"
                                    className="divide-y divide-gray-200 rounded-md border border-gray-200"
                                  >
                                    {actividades.map((data) => (
                                      <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm ">
                                        <div className="flex w-0 flex-1 items-center">
                                          <Squares2X2Icon
                                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                                            aria-hidden="true"
                                          />
                                          <span className="ml-2 w-0 flex-1 truncate">
                                            {data.descripcion}
                                          </span>
                                        </div>
                                        <div className="ml-4 flex-shrink-0">
                                          <p
                                            className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
                                            onClick={() => {
                                              setActividad(data);
                                              setOpenModalInfoActividad(true);
                                            }}
                                          >
                                            Ver
                                          </p>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </dd>
                              </div>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      onClick={() => setOpenModalInfo(false)}
                    >
                      Aceptar
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => setOpenModalInfo(false)}
                      ref={cancelButtonRef}
                    >
                      Cancelar
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={openModalInfoActividad} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonActividadRef}
          onClose={setOpenModalInfoActividad}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl h-[65vh] w-[60vw] transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Actividad
                      </Dialog.Title>
                      <div className="mt-2">
                        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                          <div className="border-t border-gray-200">
                            <dl>
                              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Codigo interno
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {actividad ? actividad.codigoInterno : "-"}
                                </dd>
                              </div>
                              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Descripcion
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {actividad ? actividad.descripcion : "-"}
                                </dd>
                              </div>
                              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Estado
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {actividad ? actividad.estado : "-"}
                                </dd>
                              </div>
                              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Grupo
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {actividad ? actividad.grupo : "-"}
                                </dd>
                              </div>
                              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Subgrupo
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {actividad ? actividad.subgrupo : "-"}
                                </dd>
                              </div>
                              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Tipo
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {actividad ? actividad.tipo : "-"}
                                </dd>
                              </div>

                              {/*<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Adjuntos
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 max-h-[95px] overflow-auto">
                                  <ul
                                    role="list"
                                    className="divide-y divide-gray-200 rounded-md border border-gray-200"
                                  >
                                    actividades.map((data) => (
                                      <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                        <div className="flex w-0 flex-1 items-center">
                                          <Squares2X2Icon
                                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                                            aria-hidden="true"
                                          />
                                          <span className="ml-2 w-0 flex-1 truncate">
                                            {data.descripcion}
                                          </span>
                                        </div>
                                        <div className="ml-4 flex-shrink-0">
                                          <a
                                            href="#"
                                            className="font-medium text-indigo-600 hover:text-indigo-500"
                                          >
                                            Ver
                                          </a>
                                        </div>
                                      </li>
                                    ))
                                  </ul>
                                </dd>
                              </div>*/}
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      onClick={() => setOpenModalInfoActividad(false)}
                    >
                      Aceptar
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => setOpenModalInfoActividad(false)}
                      ref={cancelButtonRef}
                    >
                      Cancelar
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
