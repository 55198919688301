import { useState } from "react";
//import { useRouter } from "next/router";

export default function LayoutActividades({ children }) {
  const [tabSelected, setTabSelected] = useState("Listado");
  //const router = useRouter();
  const tabs = [
    { name: "Listado", href: "actividades" },
    { name: "Crear", href: "actividades/crear" },
    { name: "Ver", href: "actividades/ver" },
  ].map((menuItem) => ({
    ...menuItem,
    getHref: () => (menuItem.primary ? `/` : `/${menuItem.href}`),
    current: function getCurrent(currentPath) {
      return currentPath === this.getHref();
    },
  }));

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div className="w-full">
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            //defaultValue={tabs.find((tab) => tab.current(router.asPath)).name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex" aria-label="Tabs">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={"/" + tab.href}
                  className={classNames(
                    /*tab.current(router.asPath)
                      ? "border-indigo-500 text-indigo-600"
                      : */ "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm"
                  )}
                  aria-current={
                    /*tab.current(router.asPath) ? "page" :*/ undefined
                  }
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
}
