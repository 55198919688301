import { Fragment, useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { MarkerWithLabel } from "@googlemaps/markerwithlabel";
import {
  obtenerEstadoActividad,
  obtenerGruposActividad,
  obtenerSubgruposActividad,
  obtenerTipoActividad,
  obtenerUsuarioDispositivos,
  crearActividad,
  crearGrupoActividad,
  crearTipoActividad,
  crearSubgrupoActividad,
  uploadFile,
} from "../../api/actividades";
import Spinner from "../../components/spinner";
import AddressAutoComplete from "../../components/address-autocomplete";

export default function CrearActividades() {
  const [loading, setLoading] = useState(false);

  const [openModalUbicacion, setOpenModalUbicacion] = useState(false);
  const [openModalTipoActividad, setOpenModalTipoActividad] = useState(false);
  const [openModalGrupo, setOpenModalGrupo] = useState(false);
  const [openModalSubgrupo, setOpenModalSubgrupo] = useState(false);
  const [newGrupo, setNewGrupo] = useState(null);
  const [newSubgrupo, setNewSubgrupo] = useState(null);
  const [newTipo, setNewTipo] = useState(null);
  const [ListEstadoActividad, setListEstadoActividad] = useState([]);
  const [ListGrupoActividad, setListGrupoActividad] = useState([]);
  const [ListSubgrupoActividad, setListSubgrupoActividad] = useState([]);
  const [ListTipoActividad, setListTipoActividad] = useState([]);
  const [listUsuariosDispositivos, setListUsuariosDispositivos] = useState([]);
  const [map, setMap] = useState(null);
  const [coordenadas, setCoordenadas] = useState(null);
  const [address, setAddress] = useState(null);
  const [google, setGoogle] = useState(null);
  const [marker, setMarker] = useState(null);
  const [haveFile, setHaveFile] = useState(false);
  const [dataCreate, setDataCreate] = useState({
    codigoInterno: null,
    idSubgrupoActividad: "",
    idGrupo: "",
    descripcion: null,
    idUsuarioDispositivo: "",
    fechaRealizar: null,
    idEstadoActividad: "",
    lat: null,
    lng: null,
    radio: null,
    observacion: null,
    idTipoActividad: "",
    //fileUpload: null,
  });
  const [fileUpload, setFileUpload] = useState(null);
  const history = useHistory();

  const nextPage = (page) => {
    try {
      history.push(`${page}`);
    } catch (err) {
      console.log("nextPage err");
      console.log(err);
    }
  };

  const upload = useRef(null);
  const uploadImage = useRef(null);
  const cancelButtonRef = useRef(null);
  const cancelButtonTipoRef = useRef(null);
  const cancelButtonGrupoRef = useRef(null);
  const cancelButtonSubgrupoRef = useRef(null);

  const traerTipoActividad = async () => {
    const resp = await obtenerTipoActividad();
    setListTipoActividad(resp.body.body);
    console.log(resp);
  };
  const traerEstadoActividad = async () => {
    const resp = await obtenerEstadoActividad();
    setListEstadoActividad(resp.body.body);
    console.log(resp);
  };
  const traerGrupoActividad = async () => {
    const resp = await obtenerGruposActividad();
    setListGrupoActividad(resp.body.body);
    console.log(resp);
  };
  const traerSubgrupoActividad = async (idGrupo) => {
    const resp = await obtenerSubgruposActividad(idGrupo);
    setListSubgrupoActividad(resp.body.body);
    console.log(resp);
  };
  const traerUsuarioDispositivo = async () => {
    const resp = await obtenerUsuarioDispositivos();
    setListUsuariosDispositivos(resp.body.body);
    console.log(resp);
  };
  const mapInit = () => {
    console.log("init");
    var boltracksrl = { lat: -17.759, lng: -63.194 };
    let mapNew = new google.maps.Map(
      document.getElementById("gmapsCanvasContainer"),
      {
        zoom: 2,
        center: boltracksrl,
        zoomControl: true,
        mapTypeControl: false,
        mapTypeControlOptions: {
          mapTypeIds: ["hybrid", "roadmap", "satellite", "terrain"],
          position: google.maps.ControlPosition.TOP_RIGHT,
        },
        streetViewControl: true,
        fullScreenControl: true,
        scrollwheel: true,
        noClear: true,
      }
    );
    mapNew.addListener("click", (mapsMouseEvent) => {
      let coord = mapsMouseEvent.latLng.toJSON();
      setAddress("Ubicacion Personzalizada");
      setCoordenadas(coord);
    });
    setMap(mapNew);
  };

  function createEventChangeInputFile() {
    const input = upload.current;
    const imageName = uploadImage.current;
    const inputImage = input.files[0];
    imageName.innerHTML = `
      <span>
        ${inputImage.name}
      </span>`;
    setHaveFile(true);
  }
  const guardarTipoActividad = async (e) => {
    e.preventDefault();
    const resp = await crearTipoActividad({ nombre: newTipo });
    console.log(resp);
    traerTipoActividad();
    alert("se creo correctamente");
    setOpenModalTipoActividad(false);
  };
  const guardarGrupoActividad = async (e) => {
    e.preventDefault();
    const resp = await crearGrupoActividad({ nombre: newGrupo });
    console.log(resp);
    traerGrupoActividad();
    alert("se creo correctamente");
    setOpenModalGrupo(false);
  };
  const guardarSubgrupoActividad = async (e) => {
    e.preventDefault();
    const resp = await crearSubgrupoActividad({
      nombre: newSubgrupo,
      idGrupo: dataCreate.idGrupo,
    });
    console.log(resp);
    traerSubgrupoActividad();
    alert("se creo correctamente");
    setOpenModalSubgrupo(false);
  };
  const guardarActividad = async (e) => {
    e.preventDefault();
    setLoading(true);
    const resp = await crearActividad(dataCreate);
    if (fileUpload != null) {
      const imageData = new FormData();
      imageData.append("photo", fileUpload, fileUpload.name);
      const respFile = await uploadFile({
        codInterno: dataCreate.codigoInterno,
        nameFile: fileUpload.name.split(".").slice(0, -1).join("."),
        file: imageData,
      });
    }
    alert("se creo correctamente");
    setFileUpload(null);
    setLoading(false);
    nextPage("/actividades");
  };
  useEffect(() => {
    traerEstadoActividad();
    traerTipoActividad();
    traerGrupoActividad();
    traerUsuarioDispositivo();
    const script = document.createElement("script");
    script.src = "https://www.google.com/jsapi";
    script.onload = () => {
      setGoogle(window.google);
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const centerAndZoomMap = (coordenadas) => {
    const center = new google.maps.LatLng(coordenadas.lat, coordenadas.lng);
    map.setCenter(center);
    map.setZoom(18);
  };
  useEffect(() => {
    if (dataCreate.idGrupo !== "") {
      traerSubgrupoActividad(dataCreate.idGrupo);
    }
  }, [dataCreate.idGrupo]);

  useEffect(() => {
    if (openModalUbicacion) {
      setTimeout(() => {
        if (google) {
          mapInit();
        }
      }, 1000);
    }
  }, [openModalUbicacion]);

  useEffect(() => {
    console.log("entra aca 1");
    if (coordenadas) {
      console.log(marker);
      if (marker !== null) {
        marker.setVisible(false);
      }
      //
      console.log("entra aca 2");
      const mark = new MarkerWithLabel({
        position: new google.maps.LatLng(coordenadas.lat, coordenadas.lng),

        clickable: true,

        map: map,
        labelContent: `<div style='color: white; background-color: #0F3457;border-radius: 5px; font-weight: 900;padding-left: 10px; padding-right: 10px;'>
          <p>Actividad</p>
           </div>`, // can also be HTMLElement
        labelAnchor: new google.maps.Point(-31, 3),
        labelClass: "labels", // the CSS class for the label
        labelStyle: { opacity: 1.0 },
      });
      setMarker(mark);
      centerAndZoomMap(coordenadas);
      setDataCreate({
        ...dataCreate,
        lat: coordenadas.lat,
        lng: coordenadas.lng,
      });
    }
  }, [coordenadas]);
  if (loading) {
    return (
      <div className=" w-1/2 m-auto px-[2rem] ">
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div className="space-y-6  sm:space-y-5 sm:pt-10">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Informacion de Actividad
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Ingrese los datos de la actividad a crear.
                </p>
              </div>
              <div className="h-[400px]  flex">
                <div className="m-auto">
                  <Spinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className=" w-1/2 m-auto px-[2rem] ">
        <form
          className="space-y-8 divide-y divide-gray-200"
          onSubmit={guardarActividad}
        >
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div className="space-y-6  sm:space-y-5 sm:pt-10">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Informacion de Actividad
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Ingrese los datos de la actividad a crear.
                </p>
              </div>
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px "
                  >
                    Codigo interno
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      className="block w-full max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      value={dataCreate.codigoInterno}
                      onChange={(e) => {
                        setDataCreate({
                          ...dataCreate,
                          codigoInterno: e.target.value,
                        });
                        console.log(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="tipo"
                    className="block text-sm font-medium text-gray-700 sm:mt-px "
                  >
                    Tipo de actividad
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0 flex">
                    <select
                      id="tipo"
                      name="tipo"
                      autoComplete="tipo-name"
                      className="block w-full max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      value={dataCreate.idTipoActividad}
                      //disabled={isSubmitting}
                      onChange={(e) => {
                        setDataCreate({
                          ...dataCreate,
                          idTipoActividad: e.target.value,
                        });
                        console.log(e.target.value);
                      }}
                    >
                      <option value="" disabled hidden>
                        Seleccione un tipo
                      </option>
                      {ListTipoActividad.map((data) => (
                        <option value={data.id}>{data.nombre}</option>
                      ))}
                    </select>
                    <button
                      className="ml-[5px]"
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenModalTipoActividad(true);
                      }}
                    >
                      <PlusCircleIcon className="h-6 w-6 " />
                    </button>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="grupo"
                    className="block text-sm font-medium text-gray-700 sm:mt-px "
                  >
                    Grupo
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0 flex">
                    <select
                      id="grupo"
                      name="grupo"
                      autoComplete="grupo-name"
                      className="block w-full max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      value={dataCreate.idGrupo}
                      onChange={(e) => {
                        setDataCreate({
                          ...dataCreate,
                          idGrupo: e.target.value,
                        });
                        console.log(e.target.value);
                      }}
                    >
                      <option value="" disabled hidden>
                        Seleccione un Grupo
                      </option>
                      {ListGrupoActividad.map((data) => (
                        <option value={data.id}>{data.nombre}</option>
                      ))}
                    </select>
                    <button
                      className="ml-[5px]"
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenModalGrupo(true);
                      }}
                    >
                      <PlusCircleIcon className="h-6 w-6 " />
                    </button>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="tipo"
                    className="block text-sm font-medium text-gray-700 sm:mt-px "
                  >
                    Subgrupo
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0 flex">
                    <select
                      id="tipo"
                      name="tipo"
                      autoComplete="tipo-name"
                      className="block w-full max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      value={dataCreate.idSubgrupoActividad}
                      onChange={(e) => {
                        setDataCreate({
                          ...dataCreate,
                          idSubgrupoActividad: e.target.value,
                        });
                        console.log(e.target.value);
                      }}
                    >
                      <option value="" disabled hidden>
                        Seleccione un Subgrupo
                      </option>
                      {ListSubgrupoActividad.map((data) => (
                        <option value={data.id}>{data.nombre}</option>
                      ))}
                    </select>
                    <button
                      className="ml-[5px]"
                      onClick={(e) => {
                        e.preventDefault();
                        if (dataCreate.idGrupo != "") {
                          setOpenModalSubgrupo(true);
                        } else {
                          alert("Seleccione un grupo primero");
                        }
                      }}
                    >
                      <PlusCircleIcon className="h-6 w-6 " />
                    </button>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px "
                  >
                    Descripcion
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      name="last-name"
                      value={dataCreate.descripcion}
                      id="last-name"
                      autoComplete="family-name"
                      className="block w-full max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      onChange={(e) => {
                        setDataCreate({
                          ...dataCreate,
                          descripcion: e.target.value,
                        });
                        console.log(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="Asignar"
                    className="block text-sm font-medium text-gray-700 sm:mt-px "
                  >
                    Asignar
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      id="Asignar"
                      name="Asignar"
                      autoComplete="Asignar-name"
                      className="block w-full max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      value={dataCreate.idUsuarioDispositivo}
                      onChange={(e) => {
                        setDataCreate({
                          ...dataCreate,
                          idUsuarioDispositivo: e.target.value,
                        });
                        console.log(e.target.value);
                      }}
                    >
                      <option value="" disabled hidden>
                        Seleccione un usuario
                      </option>
                      {listUsuariosDispositivos.map((data) => (
                        <option value={data.id}>{data.nombre}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="street-address"
                    className="block text-sm font-medium text-gray-700 sm:mt-px "
                  >
                    Fecha a realizar
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="date"
                      name="street-address"
                      id="street-address"
                      autoComplete="street-address"
                      className="block w-full max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      value={dataCreate.fechaRealizar}
                      onChange={(e) => {
                        setDataCreate({
                          ...dataCreate,
                          fechaRealizar: e.target.value,
                        });
                        console.log(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="estado"
                    className="block text-sm font-medium text-gray-700 sm:mt-px "
                  >
                    Estado
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      id="estado"
                      name="estado"
                      autoComplete="estado-name"
                      className="block w-full max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      value={dataCreate.idEstadoActividad}
                      onChange={(e) => {
                        setDataCreate({
                          ...dataCreate,
                          idEstadoActividad: e.target.value,
                        });
                        console.log(e.target.value);
                      }}
                    >
                      <option value="" disabled hidden>
                        Seleccione un Estado
                      </option>
                      {ListEstadoActividad.map((data) => (
                        <option value={data.id}>{data.nombre}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="region"
                    className="block text-sm font-medium text-gray-700 sm:mt-px "
                  >
                    Ubicacion
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0 flex">
                    <div className="flex">
                      <input
                        type="text"
                        name="region"
                        id="region"
                        placeholder="lat"
                        autoComplete="address-level1"
                        className="block w-full max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 mr-[10px] focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                        value={dataCreate.lat}
                      />
                      <input
                        type="text"
                        name="region"
                        id="region"
                        placeholder="lng"
                        autoComplete="address-level1"
                        className="block w-full max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                        value={dataCreate.lng}
                      />
                    </div>
                    <button
                      className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenModalUbicacion(true);
                      }}
                    >
                      Agregar
                    </button>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px "
                  >
                    Radio
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      name="last-name"
                      value={dataCreate.radio}
                      id="last-name"
                      autoComplete="family-name"
                      className="block w-full max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      onChange={(e) => {
                        setDataCreate({
                          ...dataCreate,
                          radio: e.target.value,
                        });
                        console.log(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px "
                  >
                    Observacion
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      name="last-name"
                      value={dataCreate.observacion}
                      id="last-name"
                      autoComplete="family-name"
                      className="block w-full max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      onChange={(e) => {
                        setDataCreate({
                          ...dataCreate,
                          observacion: e.target.value,
                        });
                        console.log(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="cover-photo"
                  className="block text-sm font-medium text-gray-700 sm:mt-px "
                >
                  {"Archivo (opcional)"}
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <div className="flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                    <div className="space-y-1 text-center">
                      <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                        onClick={() => {
                          setHaveFile(false);
                        }}
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div
                        className={`flex text-sm text-gray-600 ${
                          haveFile && "hidden"
                        }`}
                      >
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer m-auto rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          <input
                            ref={upload}
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            className="sr-only"
                            onChange={(e) => {
                              setFileUpload(e.target.files[0]);
                              createEventChangeInputFile();
                            }}
                          />
                          <span>Suba un archivo</span>
                        </label>
                      </div>
                      <p
                        className={`text-xs text-gray-500 ${
                          haveFile && "hidden"
                        }`}
                      >
                        PNG, JPG, PDF up to 10MB
                      </p>
                      <div ref={uploadImage} id="divFile" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="button"
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>

      <Transition.Root show={openModalUbicacion} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenModalUbicacion}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl h-[80vh] w-[60vw] transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Seleccionar Ubicacion
                      </Dialog.Title>
                      <AddressAutoComplete
                        id="address"
                        name="address"
                        label="Address"
                        value={address}
                        onSelect={(value) => {
                          setAddress(value.name);
                          setCoordenadas(value.coordenadas);

                          console.log(value);
                          //autocompleteAddress(value, setFieldValue);
                        }}
                        onChange={(e) => {
                          setAddress(e.target.value.name);
                          setCoordenadas(e.target.value.coordenadas);
                        }}
                        required
                      />
                      <div className="mt-2">
                        <div
                          id="gmapsCanvasContainer"
                          style={{
                            width: "100%",
                            minHeight: "500px",
                            height: "100%",
                            zIndex: 1,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      onClick={() => setOpenModalUbicacion(false)}
                    >
                      Aceptar
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => setOpenModalUbicacion(false)}
                      ref={cancelButtonRef}
                    >
                      Cancelar
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={openModalTipoActividad} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonTipoRef}
          onClose={setOpenModalTipoActividad}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl h-[20vh] w-[60vw] transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Registrar Tipo Actividad
                      </Dialog.Title>
                      <div className="mt-2 flex justify-between">
                        <p className="pr-[5px]">Nombre:</p>
                        <input
                          type="text"
                          className="rounded-md border-gray-300 border-[1px] w-full"
                          onChange={(e) => {
                            setNewTipo(e.target.value);
                            console.log(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      onClick={guardarTipoActividad}
                    >
                      Guardar
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => setOpenModalTipoActividad(false)}
                      ref={cancelButtonRef}
                    >
                      Cancelar
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={openModalGrupo} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonGrupoRef}
          onClose={setOpenModalGrupo}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl h-[20vh] w-[60vw] transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Registrar Grupo
                      </Dialog.Title>
                      <div className="mt-2 flex justify-between">
                        <p className="pr-[5px]">Nombre:</p>
                        <input
                          type="text"
                          className="rounded-md border-gray-300 border-[1px] w-full"
                          onChange={(e) => {
                            setNewGrupo(e.target.value);
                            console.log(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      onClick={guardarGrupoActividad}
                    >
                      Guardar
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => setOpenModalGrupo(false)}
                      ref={cancelButtonRef}
                    >
                      Cancelar
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={openModalSubgrupo} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonSubgrupoRef}
          onClose={setOpenModalSubgrupo}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl h-[20vh] w-[60vw] transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Registrar Subgrupo
                      </Dialog.Title>
                      <div className="mt-2 flex justify-between">
                        <p className="pr-[5px]">Nombre:</p>
                        <input
                          type="text"
                          className="rounded-md border-gray-300 border-[1px] w-full"
                          onChange={(e) => {
                            setNewSubgrupo(e.target.value);
                            console.log(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      onClick={guardarSubgrupoActividad}
                    >
                      Guardar
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => setOpenModalSubgrupo(false)}
                      ref={cancelButtonRef}
                    >
                      Cancelar
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
