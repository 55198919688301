import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ChevronRightIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import moment from "moment";
import { MarkerWithLabel } from "@googlemaps/markerwithlabel";
/*import { eventoDispositivosEmpresa } from "../api/rastreo";
import { actividadesDispositivo } from "../api/actividades";*/
import { obtenerUsuariosDispositivos, obtenerEmpresa } from "../../api/usuario";
import {
  actividadesEmpresa,
  obtenerAdjuntos,
  downloadFile,
  obtenerEstadoActividad,
  obtenerGruposActividad,
  obtenerSubgruposActividad,
  obtenerTipoActividad,
  obtenerUsuarioDispositivos,
  actividadXId,
  modificarActividad,
} from "../../api/actividades";
import Spinner from "../../components/spinner";

export default function Rastreo() {
  const [loading, setLoading] = useState(false);
  const [hiddenGrid, setHiddenGrid] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [openModalInfoActividad, setOpenModalInfoActividad] = useState(false);
  const [map, setMap] = useState(null);
  const [google, setGoogle] = useState(null);
  const [verAdjunto, setVerAdjunto] = useState(false);
  const [blobArchivo, setBlobArchivo] = useState(null);
  const [configAdjuntos, setConfigAdjuntos] = useState(null);
  const [markerWithLabel, setMarkerWithLabel] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [listaAdjuntos, setListaAdjuntos] = useState([]);
  const [loadingMap, setLoadingMap] = useState(false);
  const [dispositivosData, setDispositivosData] = useState([]);
  const [dispositivosDataAnidado, setDispositivosDataAnidado] = useState([]);
  const [dispositivoData, setDispositivoData] = useState(null);
  const [nombreArchivo, setNombreArchivo] = useState(null);
  const [actividades, setActividades] = useState([]);
  const [actividad, setActividad] = useState(null);

  const cancelButtonRef = useRef(null);
  const cancelButtonActividadRef = useRef(null);

  const mapInit = () => {
    console.log("init");
    var boltracksrl = { lat: -17.759, lng: -63.194 };
    let mapNew = new google.maps.Map(
      document.getElementById("gmapsCanvasContainer"),
      {
        zoom: 8,
        center: boltracksrl,
        zoomControl: true,
        mapTypeControl: false,
        mapTypeControlOptions: {
          mapTypeIds: ["hybrid", "roadmap", "satellite", "terrain"],
          position: google.maps.ControlPosition.TOP_RIGHT,
        },
        streetViewControl: true,
        fullScreenControl: true,
        scrollwheel: true,
        noClear: true,
      }
    );
    setMap(mapNew);
  };

  const obtenerConfig = async () => {
    const resp = await obtenerEmpresa();
    const {
      urlServidorArchivo: host,
      usuarioServidorArchivo: user,
      contracenaServidorArchivo: password,
    } = resp.body.body[0];
    setConfigAdjuntos({ host, user, password });
  };
  const obtenerAttachement = async () => {
    //host, user, password, codInterno, name
    try {
      const data = { ...configAdjuntos, codInterno: actividad.codigoInterno };
      const resp = await obtenerAdjuntos(data);
      setListaAdjuntos(resp.body);
      console.log(resp);
    } catch (e) {
      console.log(e);
      setListaAdjuntos([]);
    }
  };
  const handleCheckboxClick = (id) => {
    console.log(markers[id]);
    markers[id].marker.setVisible(!markers[id].marker.visible);
  };
  const handleGroupCheckboxClick = (grupo) => {
    console.log(markers);
    Object.values(markers).forEach((markerData) => {
      if (markerData.grupo === grupo) {
        markerData.marker.setVisible(!markerData.marker.visible);
      }
    });
  };
  const handleSubgroupCheckboxClick = (subgrupo) => {
    Object.values(markers).forEach((markerData) => {
      if (markerData.subgrupo === subgrupo) {
        markerData.marker.setVisible(!markerData.marker.visible);
      }
    });
  };
  const obtenerBufferDeAdjunto = async () => {
    const data = {
      ...configAdjuntos,
      codInterno: actividad.codigoInterno,
      name: nombreArchivo,
    };
    const resp = await downloadFile(data);

    setBlobArchivo(URL.createObjectURL(resp));
  };
  const obtenerActividades = async () => {
    const resp = await actividadesEmpresa();
    const usuarios = await obtenerUsuariosDispositivos();
    console.log(usuarios);
    const nullValues = {
      id: null,
      nombre: null,
      cel: null,
    };
    const newArray = resp.body.body.map((item) => {
      const matchingItem = usuarios.body.find(
        (obj) => obj.id === item.idUsuarioDispositivo
      );
      return matchingItem
        ? { ...item, ...matchingItem, idActividad: item.id }
        : { ...item, ...nullValues, idActividad: item.id };
    });

    console.log(newArray);
    setDispositivosData(newArray);
    const dispositivosAnidados = newArray.reduce((acumulador, actividad) => {
      const { grupo, subgrupo } = actividad;

      if (!acumulador[grupo]) {
        acumulador[grupo] = {};
      }

      if (!acumulador[grupo][subgrupo]) {
        acumulador[grupo][subgrupo] = [];
      }

      acumulador[grupo][subgrupo].push(actividad);

      return acumulador;
    }, {});
    console.log(dispositivosAnidados);
    setDispositivosDataAnidado(dispositivosAnidados);
    let markerList = {};
    console.log(map);
    for (let x = 0; x < newArray.length; x++) {
      markerList[newArray[x].codigoInterno] = {
        marker: null,
        grupo: newArray[x].grupo,
        subgrupo: newArray[x].subgrupo,
      };
      markerList[newArray[x].codigoInterno].marker = new MarkerWithLabel({
        position: new google.maps.LatLng(newArray[x].lat, newArray[x].lng),

        //clickable: true,

        map: map,
        labelContent: `<div style='color: white; background-color: #0F3457;border-radius: 5px; font-weight: 900;padding-left: 10px; padding-right: 10px;'>
          <p>${newArray[x].codigoInterno}</p>
           </div>`, // can also be HTMLElement
        labelAnchor: new google.maps.Point(-21, 3),
        labelClass: "labels", // the CSS class for the label
        labelStyle: { opacity: 1.0 },
      });
      markerList[newArray[x].codigoInterno].marker.addListener("click", () => {
        console.log("entra al click");
        setDispositivoData(newArray[x]);
        setOpenModalInfo(true);

        //infowindow.open(map, markerList[newArray[x].codigoInterno]);
      });
    }
    setMarkers(markerList);
    console.log(newArray);
  };

  const centerAndZoomMap = (coordenadas) => {
    const center = new window.google.maps.LatLng(
      coordenadas.lat,
      coordenadas.lng
    );
    map.panTo(center);
    map.setZoom(14);
  };
  useEffect(() => {
    if (actividad) {
      obtenerAttachement();
    }
  }, [actividad]);
  useEffect(() => {
    obtenerConfig();
    const script = document.createElement("script");
    script.src = "https://www.google.com/jsapi";
    script.onload = () => {
      setGoogle(window.google);
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useEffect(() => {
    if (google) {
      mapInit();
    } else {
    }
  }, [google]);

  useEffect(() => {
    if (map) {
      obtenerActividades();
      const interval = setInterval(() => {
        obtenerActividades();
      }, 12000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [map]);
  useEffect(() => {
    if (dispositivoData) {
      //obtenerActividades();
    }
  }, [dispositivoData]);
  useEffect(() => {
    if (nombreArchivo) {
      obtenerBufferDeAdjunto();
      setVerAdjunto(true);
    }
  }, [nombreArchivo]);
  return (
    <>
      <div className="flex">
        <div
          className={`w-[30px] flex bg-[#091d30] h-screen ${
            !hiddenGrid ? "hidden" : "block"
          }`}
          onClick={() => {
            setHiddenGrid(false);
          }}
        >
          <ChevronRightIcon className="text-white m-auto" />
        </div>
        <div
          className={`min-w-[300px] w-[30vw] bg-[#091d30] h-screen p-[10px] ${
            hiddenGrid ? "hidden" : "block"
          }`}
        >
          <div className="pb-[5px] flex justify-center">
            <p className="text-white font-medium w-[90%] text-center">
              Seguimiento Online
            </p>
            <p
              className="text-white font-medium text-rigth"
              onClick={() => {
                setHiddenGrid(true);
              }}
            >
              X
            </p>
          </div>
          <div className="bg-[#113A5F] h-[97%] mb-[16px] w-full rounded-md p-[5px]">
            <div className="bg-[#091d30] h-auto pb-[5px] w-full rounded-md ">
              {Object.keys(dispositivosDataAnidado).map((dispositivosGrupo) => (
                <>
                  <div className="flex p-[5px] ">
                    <input
                      type="checkbox"
                      defaultChecked={true}
                      className="h-4 w-4 my-auto rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 mr-[10px] ml-[5px]"
                      onChange={() => {
                        handleGroupCheckboxClick(dispositivosGrupo);
                      }}
                    />
                    <p className="text-white font-medium">
                      {dispositivosGrupo}
                    </p>
                  </div>
                  {Object.keys(dispositivosDataAnidado[dispositivosGrupo]).map(
                    (dispositivosSubgrupo) => (
                      <div
                        className="bg-[#113A5F] mx-[10px] my-[5px] rounded pb-[0.5px]"
                        style={{ height: "auto" }}
                      >
                        <div className="flex p-[5px]">
                          <input
                            type="checkbox"
                            defaultChecked={true}
                            className="h-4 w-4 my-auto rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 mr-[10px] ml-[5px] "
                            onChange={() => {
                              handleSubgroupCheckboxClick(dispositivosSubgrupo);
                            }}
                          />
                          <p className="text-white font-medium">
                            {dispositivosSubgrupo}
                          </p>
                        </div>
                        <div className="bg-white mb-[15px]">
                          <div className="flex text-[11px] px-[5px] border-b-2 px-[15px]">
                            <p className="w-1/6 my-auto">Ver</p>
                            <p className="w-2/6 my-auto">Nombre</p>
                            <p className="w-1/4 my-auto text-center">Estado</p>
                            <p className="w-1/4 my-auto leading-3">
                              Fecha a Realizar
                            </p>
                          </div>
                          {dispositivosDataAnidado[dispositivosGrupo][
                            dispositivosSubgrupo
                          ].map((data) => (
                            <div className="flex text-[11px] px-[5px] border-b-[1px] px-[15px] cursor-pointer">
                              <div className="w-1/6 flex">
                                <input
                                  defaultChecked={true}
                                  type="checkbox"
                                  className=" h-3 w-3 my-auto mx-[2px] rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  onChange={() =>
                                    handleCheckboxClick(data.codigoInterno)
                                  }
                                />
                              </div>
                              <p
                                className="w-2/6 my-auto"
                                onClick={() => {
                                  console.log(data);
                                  centerAndZoomMap({
                                    lat: data.lat,
                                    lng: data.lng,
                                  });
                                }}
                              >
                                {data.codigoInterno}
                              </p>
                              <div className="w-1/4 my-auto p-[1px]">
                                <div
                                  className={`w-full h-[20px] rounded-full flex ${
                                    data.estado == "Activo" ? "bg-gray-200" : ""
                                  } ${
                                    data.estado == "Pendiente"
                                      ? "bg-gray-200"
                                      : ""
                                  } ${
                                    data.estado == "Rechazada"
                                      ? "bg-red-100"
                                      : ""
                                  } ${
                                    data.estado == "Realizado"
                                      ? "bg-green-100"
                                      : ""
                                  } ${
                                    data.estado == "Sin asignar"
                                      ? "bg-yellow-100"
                                      : ""
                                  }`}
                                  /*
                                  className={`inline-flex rounded-full w-[81px] ${
                              data.estado == "Activo" && "bg-gray-200"
                            } ${data.estado == "Pendiente" && "bg-gray-200"} ${
                              data.estado == "Cancelado" && "bg-red-100"
                            } ${data.estado == "Realizado" && "bg-green-100"} ${
                              data.estado == "Sin asignar" && "bg-yellow-100"
                            } px-2 text-xs font-semibold leading-5 text-black-800 flex`}
                                  */
                                >
                                  <span
                                    className={`rounded-full my-auto w-full px-2 text-[10px] text-center font-semibold text-black-800`}
                                  >
                                    {data.estado}
                                  </span>
                                </div>
                              </div>
                              <p className="w-1/4 my-auto">
                                {moment(data.fechaRealizar).format(
                                  "YYYY-MM-DD"
                                )}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
        {!loadingMap ? (
          <div
            id="gmapsCanvasContainer"
            style={{
              width: "100%",
              minHeight: "855px",
              height: "100vh",
              zIndex: 1,
            }}
          ></div>
        ) : (
          <div>Cargando...</div>
        )}
      </div>
      <Transition.Root show={openModalInfo} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenModalInfo}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl min-h-[630px] h-[65vh] w-[60vw] transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  {verAdjunto === false ? (
                    <>
                      <div>
                        <div className="mt-3 text-center h-auto sm:mt-5">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            Informacion
                          </Dialog.Title>
                          <div className="mt-2">
                            <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                              <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                    <div className="sm:col-span-1">
                                      <dt className="text-sm font-medium text-gray-500">
                                        Nombre
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900">
                                        {dispositivoData
                                          ? dispositivoData.nombre
                                          : "-"}
                                      </dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <dt className="text-sm font-medium text-gray-500">
                                        Celular/Telefono
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900">
                                        {dispositivoData
                                          ? dispositivoData.cel
                                          : "-"}
                                      </dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <dt className="text-sm font-medium text-gray-500">
                                        Email address
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900">
                                        {dispositivoData
                                          ? dispositivoData.email
                                          : "-"}
                                      </dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <dt className="text-sm font-medium text-gray-500">
                                        Tipo de actividad
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900">
                                        {dispositivoData
                                          ? dispositivoData.tipo
                                          : "-"}
                                      </dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <dt className="text-sm font-medium text-gray-500">
                                        Fecha de creacion
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900">
                                        {dispositivoData
                                          ? moment(
                                              dispositivoData.fechaHoraCreacion
                                            ).format("YYYY-MM-DD HH:mm:ss")
                                          : "-"}
                                      </dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                      <dt className="text-sm font-medium text-gray-500">
                                        Fecha a realizar
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900">
                                        {dispositivoData
                                          ? moment(
                                              dispositivoData.fechaRealizar
                                            ).format("YYYY-MM-DD HH:mm:ss")
                                          : "-"}
                                      </dd>
                                    </div>
                                    <div className="sm:col-span-2">
                                      <dt className="text-sm font-medium text-gray-500">
                                        Descripcion
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900">
                                        {dispositivoData
                                          ? dispositivoData.descripcion
                                          : "-"}
                                      </dd>
                                    </div>
                                    <div className="sm:col-span-2">
                                      <dt className="text-sm font-medium text-gray-500">
                                        Attachments
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900">
                                        <ul
                                          role="list"
                                          className="divide-y divide-gray-200 rounded-md border border-gray-200 overflow-auto max-h-[90px]"
                                        >
                                          {listaAdjuntos &&
                                            listaAdjuntos.map((data) => (
                                              <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                                <div className="flex w-0 flex-1 items-center">
                                                  <PaperClipIcon
                                                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                                                    aria-hidden="true"
                                                  />
                                                  <span className="ml-2 w-0 flex-1 truncate">
                                                    {data.name}
                                                  </span>
                                                </div>
                                                <div className="ml-4 flex-shrink-0">
                                                  <a
                                                    href="#"
                                                    className="font-medium text-indigo-600 hover:text-indigo-500"
                                                  >
                                                    Descargar
                                                  </a>
                                                </div>
                                                <div className="ml-4 flex-shrink-0">
                                                  <a
                                                    href="#"
                                                    className="font-medium text-indigo-600 hover:text-indigo-500"
                                                    onClick={() => {
                                                      setBlobArchivo(null);
                                                      setNombreArchivo(
                                                        data.name
                                                      );
                                                    }}
                                                  >
                                                    Ver
                                                  </a>
                                                </div>
                                              </li>
                                            ))}
                                        </ul>
                                      </dd>
                                    </div>
                                  </dl>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                          onClick={() => setOpenModalInfo(false)}
                        >
                          Aceptar
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                          onClick={() => setOpenModalInfo(false)}
                          ref={cancelButtonRef}
                        >
                          Cancelar
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="bg-white">
                        <div className="pt-6 pb-16 sm:pb-24 h-[55vh]">
                          <h2 className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            Archivo adjunto:
                          </h2>
                          <div className="mx-auto mt-8 max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                            <div className="lg:grid lg:auto-rows-min lg:grid-cols-12 lg:gap-x-8">
                              <div className="lg:col-span-5 lg:col-start-8">
                                <div className="block">
                                  <h1 className="text-md font-medium text-gray-900">
                                    {nombreArchivo}
                                  </h1>
                                  {/*<p className="text-md font-medium text-gray-900">
                                    24/09/2022
                  </p>*/}
                                </div>
                              </div>

                              <div className="mt-8 lg:col-span-7 lg:col-start-1 lg:row-span-3 lg:row-start-1 lg:mt-0">
                                <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-1 lg:gap-8">
                                  <img
                                    key="1"
                                    src={blobArchivo}
                                    alt=""
                                    className={
                                      "lg:col-span-2 lg:row-span-2 h-auto"
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-6 flex">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center m-auto rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                          onClick={() => setVerAdjunto(false)}
                        >
                          Atras
                        </button>
                      </div>
                    </>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={openModalInfoActividad} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonActividadRef}
          onClose={setOpenModalInfoActividad}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl h-[65vh] w-[60vw] transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Actividad
                      </Dialog.Title>
                      <div className="mt-2">
                        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                          <div className="border-t border-gray-200">
                            <dl>
                              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Codigo interno
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {dispositivoData
                                    ? dispositivoData.codigoInterno
                                    : "-"}
                                </dd>
                              </div>
                              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Descripcion
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {dispositivoData
                                    ? dispositivoData.descripcion
                                    : "-"}
                                </dd>
                              </div>
                              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Estado
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {dispositivoData
                                    ? dispositivoData.estado
                                    : "-"}
                                </dd>
                              </div>
                              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Grupo
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {dispositivoData
                                    ? dispositivoData.grupo
                                    : "-"}
                                </dd>
                              </div>
                              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Subgrupo
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {dispositivoData
                                    ? dispositivoData.subgrupo
                                    : "-"}
                                </dd>
                              </div>
                              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Tipo
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {dispositivoData ? dispositivoData.tipo : "-"}
                                </dd>
                              </div>

                              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Adjuntos
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 max-h-[95px] overflow-auto">
                                  <ul
                                    role="list"
                                    className="divide-y divide-gray-200 rounded-md border border-gray-200"
                                  >
                                    {listaAdjuntos &&
                                      listaAdjuntos.map((data) => (
                                        <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                          <div className="flex w-0 flex-1 items-center">
                                            <PaperClipIcon
                                              className="h-5 w-5 flex-shrink-0 text-gray-400"
                                              aria-hidden="true"
                                            />
                                            <span className="ml-2 w-0 flex-1 truncate">
                                              {data.name}
                                            </span>
                                          </div>
                                          <div className="ml-4 flex-shrink-0">
                                            <a
                                              href="#"
                                              className="font-medium text-indigo-600 hover:text-indigo-500"
                                            >
                                              Descargar
                                            </a>
                                          </div>
                                          <div className="ml-4 flex-shrink-0">
                                            <a
                                              href="#"
                                              className="font-medium text-indigo-600 hover:text-indigo-500"
                                              onClick={() => {
                                                setBlobArchivo(null);
                                                setNombreArchivo(data.name);
                                              }}
                                            >
                                              Ver
                                            </a>
                                          </div>
                                        </li>
                                      ))}
                                  </ul>
                                </dd>
                              </div>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      onClick={() => setOpenModalInfoActividad(false)}
                    >
                      Aceptar
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => setOpenModalInfoActividad(false)}
                      ref={cancelButtonRef}
                    >
                      Cancelar
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
