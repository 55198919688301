export async function login(usuario, contracena) {
  try {
    const { code, body } = await fetch(
      "https://gestiondeflota.boltrack.net/rastreo-movil/api/usuario/verify-usuario-web",
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ usuario, contracena }),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("login err");
    console.log(err);
    return [];
  }
}
