import { getHeaders } from "./utils";

export async function eventoDispositivosEmpresa() {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/rastreo/eventos`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "GET",
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("login err");
    console.log(err);
    return [];
  }
}
