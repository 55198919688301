import { useEffect, useState } from "react";
import Select from "react-select";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import {
  modificarDatosServidorArchivo,
  obtenerConfigServidorArchivo,
} from "../api/actividades";
import {
  grupoDispositivosEmpresa,
  subgrupoGrupoDispositivosEmpresa,
  dispositivosEmpresa,
  crearGrupoDispositivosEmpresa,
  crearSubgrupoGrupoDispositivosEmpresa,
  asignarSubgrupoGrupoDispositivosEmpresa,
} from "../api/dispositivos";

export default function Configuracion() {
  const [host, setHost] = useState(null);
  const [usuario, setUsuario] = useState(null);
  const [password, setPassword] = useState(null);

  const [showServidorArchivo, setShowServidorArchivo] = useState(false);
  const [showGestionGruposRastreo, setShowGestionGruposRastreo] =
    useState(false);
  const [showAsignarGruposRastreo, setShowAsignarGruposRastreo] =
    useState(false);
  const [showCrearGruposRastreo, setShowCrearGruposRastreo] = useState(false);
  const [showCrearSubgruposRastreo, setShowCrearSubgruposRastreo] =
    useState(false);

  const [showGestionGruposActividad, setShowGestionGruposActividad] =
    useState(false);

  const [dispositivos, setDispositivos] = useState([]);
  const [grupoDispositivo, setGrupoDispositivo] = useState([]);
  const [subgrupoDispositivo, setSubgrupoDispositivo] = useState([]);

  const [idDispositivo, setIdDispositivo] = useState(null);

  const [nombreGrupoDispositivo, setNombreGrupoDispositivo] = useState(null);
  const [nombreSubgrupoDispositivo, setNombreSubgrupoDispositivo] =
    useState(null);
  const [idGrupoDispositivo, setIdGrupoDispositivo] = useState(null);
  const [idSubgrupoDispositivo, setIdSubgrupoDispositivo] = useState(null);

  const [actividades, setActividades] = useState([]);
  const [grupoActividad, setGrupoActividad] = useState([]);

  const [idGrupoActividad, setIdGrupoActividad] = useState(null);
  const [idSubgrupoActividad, setIdSubgrupoActividad] = useState(null);

  const [loading, setLoading] = useState(false);

  const modificarDataServidorArchivo = async () => {
    setLoading(true);
    const resp = await modificarDatosServidorArchivo({
      host,
      usuario,
      password,
    });
    console.log(resp);
  };
  const obtenerConfiguracionServidorArchivo = async () => {
    const resp = await obtenerConfigServidorArchivo();
    console.log(resp);
    setHost(resp.body.urlServidorArchivo);
    setUsuario(resp.body.usuarioServidorArchivo);
  };
  const obtenerDataDispositivo = async () => {
    const resp = await dispositivosEmpresa();
    const respGrupo = await grupoDispositivosEmpresa();
    const formatDispositivo = resp.body.map((data) => {
      return { label: data.nombre, value: data.id };
    });
    const formatGrupo = respGrupo.body.map((data) => {
      return { label: data.nombre, value: data.id };
    });
    setGrupoDispositivo(formatGrupo);
    setDispositivos(formatDispositivo);
    console.log(resp);
    console.log(respGrupo);
  };
  const crearGrupo = async () => {
    const resp = await crearGrupoDispositivosEmpresa({
      nombre: nombreGrupoDispositivo,
    });
    obtenerDataDispositivo();
    alert("se creo el grupo correctamente");
  };
  const crearSubgrupo = async () => {
    const resp = await crearSubgrupoGrupoDispositivosEmpresa({
      nombre: nombreSubgrupoDispositivo,
      idGrupo: idGrupoDispositivo,
    });
    obtenerDataDispositivo();
    alert("se creo el subgrupo correctamente");
  };
  const asignarSubgrupo = async () => {
    const resp = await asignarSubgrupoGrupoDispositivosEmpresa({
      idDispositivo: idDispositivo,
      idSubgrupo: idSubgrupoDispositivo,
    });
    obtenerDataDispositivo();
    alert("se asigno el grupo y subgrupo correctamente");
  };
  useEffect(() => {
    obtenerConfiguracionServidorArchivo();
    obtenerDataDispositivo();
  }, []);
  return (
    <div className=" w-1/2 m-auto px-[2rem] ">
      <div className="space-y-8 divide-y divide-gray-200">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6  sm:space-y-5 sm:pt-10">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Configuracion
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Cambie los datos que desea modificar de la configuracion de la
                plataforma.
              </p>
            </div>
            <div className="space-y-6 sm:space-y-5">
              <div className="flex justify-beetwen">
                <h3 className="text-medium font-medium leading-6 text-gray-900 w-4/5">
                  Servidor Archivo
                </h3>
                <p
                  className="w-1/5 text-right"
                  onClick={() => {
                    setShowServidorArchivo(!showServidorArchivo);
                  }}
                >
                  <PencilSquareIcon
                    className={`${showServidorArchivo ? "text-blue-500" : ""}`}
                    width="20px"
                    height="20px"
                  />
                </p>
              </div>
              {showServidorArchivo && (
                <>
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="host"
                      className="block text-sm font-medium text-gray-700 sm:mt-px "
                    >
                      Host
                    </label>
                    <div className="mt-1  sm:col-span-2 sm:mt-0">
                      <input
                        type="text"
                        name="host"
                        id="host"
                        autoComplete="host"
                        value={host}
                        className="block w-full pl-[5px]  max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                        onChange={(e) => {
                          setHost(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="Usuario"
                      className="block text-sm font-medium text-gray-700 sm:mt-px "
                    >
                      Usuario
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <input
                        type="text"
                        name="Usuario"
                        id="Usuario"
                        autoComplete="Usuario"
                        value={usuario}
                        className="block w-full pl-[5px] max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                        onChange={(e) => {
                          setUsuario(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="Password"
                      className="block text-sm font-medium text-gray-700 sm:mt-px "
                    >
                      Password
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <input
                        type="password"
                        name="Password"
                        id="Password"
                        autoComplete="Password"
                        value={password}
                        placeholder={"************"}
                        className="block pl-[5px] w-full max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="pt-5">
                    <div className="flex justify-end">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => {
                          setShowServidorArchivo(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => {
                          modificarDataServidorArchivo();
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="space-y-6 sm:space-y-5">
              <div className="flex justify-beetwen">
                <h3 className="text-medium font-medium leading-6 text-gray-900 w-4/5">
                  Gestion de Grupos/Subgrupos rastreo
                </h3>
                <p
                  className="w-1/5 text-right"
                  onClick={() => {
                    setShowGestionGruposRastreo(!showGestionGruposRastreo);
                  }}
                >
                  <PencilSquareIcon
                    className={`${
                      showGestionGruposRastreo ? "text-blue-500" : ""
                    }`}
                    width="20px"
                    height="20px"
                  />
                </p>
              </div>
              {showGestionGruposRastreo && (
                <div>
                  <div>
                    <div className="flex justify-beetwen">
                      <h3 className="text-medium font-medium leading-6 text-gray-900 w-4/5">
                        - Asignar Grupos/Subgrupos de dispositivos
                      </h3>
                      <p
                        className="w-1/5 text-right"
                        onClick={() => {
                          setShowAsignarGruposRastreo(
                            !showAsignarGruposRastreo
                          );
                        }}
                      >
                        <PencilSquareIcon
                          className={`${
                            showAsignarGruposRastreo ? "text-blue-500" : ""
                          }`}
                          width="20px"
                          height="20px"
                        />
                      </p>
                    </div>
                    {showAsignarGruposRastreo && (
                      <>
                        <div className="mx-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="host"
                            className="block text-sm font-medium text-gray-700 sm:mt-px "
                          >
                            Dispositivos
                          </label>
                          <div className="mt-1  sm:col-span-2 sm:mt-0">
                            <Select
                              options={dispositivos}
                              onChange={(e) => {
                                setIdDispositivo(e.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="mx-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="Usuario"
                            className="block  text-sm font-medium text-gray-700 sm:mt-px "
                          >
                            Grupos
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <Select
                              options={grupoDispositivo}
                              onChange={async (e) => {
                                //const value = e.values();
                                console.log(e.value);
                                setIdGrupoDispositivo(e.value);
                                const resp =
                                  await subgrupoGrupoDispositivosEmpresa(
                                    e.value
                                  );
                                console.log(resp);
                                const formatSubgrupo = resp.body.map((data) => {
                                  return { label: data.nombre, value: data.id };
                                });
                                setSubgrupoDispositivo(formatSubgrupo);
                              }}
                            />
                          </div>
                        </div>
                        <div className="mx-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="Password"
                            className="block text-sm font-medium text-gray-700 sm:mt-px "
                          >
                            Subgrupos
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <Select
                              options={subgrupoDispositivo}
                              onChange={(e) => {
                                setIdSubgrupoDispositivo(e.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="pt-5">
                          <div className="flex justify-end">
                            <button
                              type="button"
                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => {
                                setShowAsignarGruposRastreo(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={async () => {
                                await asignarSubgrupo();
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>{" "}
                      </>
                    )}
                  </div>

                  <div className="mt-4">
                    <div className="flex justify-beetwen">
                      <h3 className="text-medium font-medium leading-6 text-gray-900 w-4/5">
                        - Crear Grupos de dispositivos
                      </h3>
                      <p
                        className="w-1/5 text-right"
                        onClick={() => {
                          setShowCrearGruposRastreo(!showCrearGruposRastreo);
                        }}
                      >
                        <PencilSquareIcon
                          className={`${
                            showCrearGruposRastreo ? "text-blue-500" : ""
                          }`}
                          width="20px"
                          height="20px"
                        />
                      </p>
                    </div>
                    {showCrearGruposRastreo && (
                      <>
                        <div className="mx-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="Usuario"
                            className="block  text-sm font-medium text-gray-700 sm:mt-px "
                          >
                            Grupos
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <input
                              type="text"
                              name="nombreGrupoDispositivo"
                              id="nombreGrupoDispositivo"
                              autoComplete="nombreGrupoDispositivo"
                              value={nombreGrupoDispositivo}
                              className="block w-full ml-[5px] pl-[5px] max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                              onChange={(e) => {
                                setNombreGrupoDispositivo(e.target.value);
                              }}
                            />
                          </div>
                        </div>

                        <div className="pt-5">
                          <div className="flex justify-end">
                            <button
                              type="button"
                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => {
                                setShowCrearGruposRastreo(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={async () => {
                                await crearGrupo();
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="mt-4">
                    <div className="flex justify-beetwen">
                      <h3 className="text-medium font-medium leading-6 text-gray-900 w-4/5">
                        - Crear Subgrupo de dispositivos
                      </h3>
                      <p
                        className="w-1/5 text-right"
                        onClick={() => {
                          setShowCrearSubgruposRastreo(
                            !showCrearSubgruposRastreo
                          );
                        }}
                      >
                        <PencilSquareIcon
                          className={`${
                            showCrearGruposRastreo ? "text-blue-500" : ""
                          }`}
                          width="20px"
                          height="20px"
                        />
                      </p>
                    </div>
                    {showCrearSubgruposRastreo && (
                      <>
                        <div className="mx-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="Usuario"
                            className="block  text-sm font-medium text-gray-700 sm:mt-px "
                          >
                            Grupos
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <div className="block w-full pl-[5px] max-w-lg rounded-md mb-2 focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm">
                              <Select
                                options={grupoDispositivo}
                                onChange={async (e) => {
                                  //const value = e.values();
                                  console.log(e.value);
                                  setIdGrupoDispositivo(e.value);
                                  const resp =
                                    await subgrupoGrupoDispositivosEmpresa(
                                      e.value
                                    );
                                  console.log(resp);
                                  const formatSubgrupo = resp.body.map(
                                    (data) => {
                                      return {
                                        label: data.nombre,
                                        value: data.id,
                                      };
                                    }
                                  );
                                  setSubgrupoDispositivo(formatSubgrupo);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mx-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="Usuario"
                            className="block  text-sm font-medium text-gray-700 sm:mt-px "
                          >
                            Subgrupos
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <input
                              type="text"
                              name="nombreSubgrupoDispositivo"
                              id="nombreSubgrupoDispositivo"
                              autoComplete="nombreSubgrupoDispositivo"
                              value={nombreSubgrupoDispositivo}
                              className="block w-full ml-[5px] pl-[5px] max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                              onChange={(e) => {
                                setNombreSubgrupoDispositivo(e.target.value);
                              }}
                            />
                          </div>
                        </div>

                        <div className="pt-5">
                          <div className="flex justify-end">
                            <button
                              type="button"
                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => {
                                setShowCrearSubgruposRastreo(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={async () => {
                                await crearSubgrupo();
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
