import React, { useEffect, useRef } from "react";

const AddressAutoComplete = ({ id, name, label, onSelect, ...props }) => {
  const addressRef = useRef(null);
  let google;
  const options = {
    //componentRestrictions: { country: "us" },
    fields: ["geometry", "formatted_address"],
    types: ["address"],
  };
  useEffect(() => {
    google = window.google;

    if (google) {
      const autocomplete = new google.maps.places.Autocomplete(
        addressRef.current,
        options
      );
      google.maps.event.addListener(
        autocomplete,
        "place_changed",
        function (e) {
          console.log(autocomplete);
          const address = autocomplete.getPlace();
          console.log(address.geometry.location.lat());
          console.log(address.geometry.location.lng());
          onSelect({
            coordenadas: {
              lat: address.geometry.location.lat(),
              lng: address.geometry.location.lng(),
            },
            name: address.formatted_address,
          });
        }
      );
    }
  }, []);

  return (
    <div>
      <label
        htmlFor={id}
        className="w-1/2 text-gray-700 text-lg xl:text-xl block"
      >
        {label}
      </label>
      <input
        type="text"
        ref={addressRef}
        placeholder="Street"
        className="w-full h-12 border px-4 border-solid border-gray-400  rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        {...props}
      />
    </div>
  );
};

export default AddressAutoComplete;
