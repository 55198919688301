import { useState, useEffect } from "react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import Select from "react-select";
import moment from "moment";
import { MarkerWithLabel } from "@googlemaps/markerwithlabel";
import { eventosHistoricoDispositivo } from "../api/historico";
import { dispositivosEmpresa } from "../api/dispositivos";
import { actividadesDispositivoEntreFecha } from "../api/actividades";
import { obtenerInicioDelDia, obtenerFinDelDia } from "../utils/tiempo";

export default function Historico() {
  const [hiddenGrid, setHiddenGrid] = useState(false);
  const [optionDispositivo, setOptionDispositivo] = useState([]);
  const [map, setMap] = useState(null);
  const [google, setGoogle] = useState(null);
  const [dispositivo, setDispositivo] = useState(null);
  const [tipoHistorico, setTipoHistorico] = useState(null);
  const [showPersonalizado, setShowPersonalizado] = useState(false);
  const [fechaHoraIniPersonalizada, setFechaHoraIniPersonalizada] =
    useState(null);
  const [fechaHoraFinPersonalizada, setFechaHoraFinPersonalizada] =
    useState(null);
  const [historicoData, setHistoricoData] = useState(null);
  const [polyline, setPolyline] = useState(null);
  const [markers, setMarkers] = useState(null);
  const [actividades, setActividades] = useState(null);
  const [distanciaTotalKm, setDistanciaTotalKm] = useState(0);
  const [distanciaTotalM, setDistanciaTotalM] = useState(0);

  const mapInit = () => {
    console.log("init");
    var boltracksrl = { lat: -17.759, lng: -63.194 };
    let mapNew = new google.maps.Map(
      document.getElementById("gmapsCanvasContainer"),
      {
        zoom: 6,
        center: boltracksrl,
        zoomControl: true,
        mapTypeControl: false,
        mapTypeControlOptions: {
          mapTypeIds: ["hybrid", "roadmap", "satellite", "terrain"],
          position: google.maps.ControlPosition.TOP_RIGHT,
        },
        streetViewControl: true,
        fullScreenControl: true,
        scrollwheel: true,
        noClear: true,
      }
    );
    setMap(mapNew);
  };
  const traerDispositivos = async () => {
    const resp = await dispositivosEmpresa();
    const option = resp.body.map((data) => {
      return { label: data.nombre, value: data.id };
    });
    setOptionDispositivo(option);
  };
  const obtenerActividades = async () => {
    const resp = await actividadesDispositivoEntreFecha(
      dispositivo,
      fechaHoraIniPersonalizada,
      fechaHoraFinPersonalizada
    );
    console.log(resp);
    setActividades(resp.body);
  };
  const formatoFecha = (fecha, hora) => {
    var dd = fecha.getDate();
    dd = ("0" + dd).slice(-2);
    var mm = fecha.getMonth() + 1;
    mm = ("0" + mm).slice(-2);
    var yyyy = fecha.getFullYear();

    fecha = `${yyyy}-${mm}-${dd} ${hora}`;
    return fecha;
  };
  const obtenerHistorico = async (fechaHoraIni, fechaHoraFin) => {
    const resp = await eventosHistoricoDispositivo(
      dispositivo,
      moment(fechaHoraIni).format("YYYY-MM-DD HH:mm:ss"),
      moment(fechaHoraFin).format("YYYY-MM-DD HH:mm:ss")
    );
    console.log(resp);
    setHistoricoData(resp.body);
  };
  const traerHistorico = async (tiempo) => {
    let fechaHoraIni;
    let fechaHoraFin;
    let tieneFechaHora = false;
    const fechaActual = new Date();
    const diaSemana = fechaActual.getDay(); // 0 (Domingo) a 6 (Sábado)
    switch (tiempo) {
      case "hoy":
        fechaHoraIni = obtenerInicioDelDia(new Date());
        fechaHoraFin = obtenerFinDelDia(new Date());
        await obtenerHistorico(fechaHoraIni, fechaHoraFin);
        setShowPersonalizado(false);
        break;
      case "ayer":
        const ayerIni = new Date();
        const ayerFin = new Date();
        ayerIni.setDate(ayerIni.getDate() - 1);
        ayerFin.setDate(ayerFin.getDate() - 1);
        fechaHoraIni = obtenerInicioDelDia(ayerIni);
        fechaHoraFin = obtenerFinDelDia(ayerFin);

        await obtenerHistorico(fechaHoraIni, fechaHoraFin);
        setShowPersonalizado(false);
        break;
      case "semanal":
        const diasHastaLunes = diaSemana === 0 ? 6 : diaSemana - 1;
        const primerDiaSemana = new Date(fechaActual);
        primerDiaSemana.setDate(fechaActual.getDate() - diasHastaLunes);
        const ultimoDiaSemana = new Date(primerDiaSemana);
        ultimoDiaSemana.setDate(primerDiaSemana.getDate() + 6);
        fechaHoraIni = obtenerInicioDelDia(primerDiaSemana);
        fechaHoraFin = obtenerFinDelDia(ultimoDiaSemana);

        await obtenerHistorico(fechaHoraIni, fechaHoraFin);
        setShowPersonalizado(false);
        break;
      case "ver-personalizado":
        setShowPersonalizado(true);
        break;
      case "personalizado-con-datos":
        fechaHoraIni = fechaHoraIniPersonalizada;
        fechaHoraFin = fechaHoraFinPersonalizada;
        await obtenerHistorico(fechaHoraIni, fechaHoraFin);
        break;
      default:
        console.log("No existe el tipo de historico");
    }
  };
  const clearMap = () => {
    if (markers) {
      markers.forEach((marker) => marker.setMap(null));
      if (polyline) {
        polyline.setMap(null);
      }
    }
  };
  //obtener distancia entre dos puntos
  var rad = function (x) {
    return (x * Math.PI) / 180;
  };

  var getDistance = function (p1, p2) {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(p2.lat - p1.lat);
    var dLong = rad(p2.lng - p1.lng);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.lat)) *
        Math.cos(rad(p2.lat)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d; // returns the distance in meter
  };
  //
  useEffect(() => {
    if (actividades) {
      let arrayMarker = markers;
      for (let x = 0; x < actividades.length; x++) {
        const newMarker = new MarkerWithLabel({
          position: { lat: actividades[x].lat, lng: actividades[x].lng },
          icon:
            actividades[x].estado === "Pendiente"
              ? "http://maps.google.com/mapfiles/ms/micons/blue.png"
              : actividades[x].estado === "Rechazada"
              ? "http://maps.google.com/mapfiles/ms/micons/orange.png"
              : "http://maps.google.com/mapfiles/ms/micons/green.png",
          map: map,
          title: actividades[x].estado,
          labelContent: `<div style='color: white; background-color: #0F3457;border-radius: 5px; font-weight: 900;padding-left: 10px; padding-right: 10px;'>
        <p>${actividades[x].codigoInterno}</p>
         </div>`, // can also be HTMLElement
          labelAnchor: new google.maps.Point(-27, 3),
          labelClass: "labels", // the CSS class for the label
          labelStyle: { opacity: 1.0 },
        });
        arrayMarker.push(newMarker);
      }
      setMarkers(arrayMarker);
    }
  }, [actividades]);
  useEffect(() => {
    if (historicoData) {
      clearMap();
      const coordenadas = historicoData.map((data) => {
        return { lat: data.lat, lng: data.lng };
      });
      let distanciaTotalEnMetros = 0;
      for (let x = 0; x < coordenadas.length; x++) {
        if (x > 0) {
          let resp = getDistance(coordenadas[x - 1], coordenadas[x]);
          distanciaTotalEnMetros = distanciaTotalEnMetros + resp;
        }
      }
      setDistanciaTotalM(distanciaTotalEnMetros);
      setDistanciaTotalKm(distanciaTotalEnMetros / 1000);
      console.log(distanciaTotalEnMetros);
      if (historicoData.length > 0) {
        const line = new window.google.maps.Polyline({
          path: coordenadas,
          geodesic: true,
          icons: [
            {
              icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
              offset: "100%",
              repeat: "50px",
            },
          ],
          strokeColor: "#FF0000", // Color de la línea
          strokeOpacity: 1.0,
          strokeWeight: 2,
          map: map,
        });
        const firstCoordinate = coordenadas[0];
        const lastCoordinate = coordenadas[coordenadas.length - 1];

        // Agregar marcador en el primer punto
        const firstMarker = new MarkerWithLabel({
          position: firstCoordinate,
          map: map,
          title: "Primer Punto",
          labelContent: `<div style='color: white; background-color: #0F3457;border-radius: 5px; font-weight: 900;padding-left: 10px; padding-right: 10px;'>
        <p>Inicio</p>
         </div>`, // can also be HTMLElement
          labelAnchor: new google.maps.Point(-27, 3),
          labelClass: "labels", // the CSS class for the label
          labelStyle: { opacity: 1.0 },
        });

        // Agregar marcador en el último punto
        const lastMarker = new MarkerWithLabel({
          position: lastCoordinate,
          map: map,
          title: "Último Punto",
          labelContent: `<div style='color: white; background-color: #0F3457;border-radius: 5px; font-weight: 900;padding-left: 10px; padding-right: 10px;'>
        <p>Fin</p>
         </div>`, // can also be HTMLElement
          labelAnchor: new google.maps.Point(-18, 3),
          labelClass: "labels", // the CSS class for the label
          labelStyle: { opacity: 1.0 },
        });

        setPolyline(line);
        setMarkers([firstMarker, lastMarker]);
        if (tipoHistorico === "actividades") {
          obtenerActividades();
        }
      }
    }
  }, [historicoData]);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/jsapi";
    script.onload = () => {
      setGoogle(window.google);
    };
    document.body.appendChild(script);
    traerDispositivos();
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useEffect(() => {
    if (google) {
      mapInit();
    } else {
    }
  }, [google]);

  return (
    <>
      <div className="flex">
        <div
          className={`w-[30px] flex bg-[#091d30] h-screen ${
            !hiddenGrid ? "hidden" : "block"
          }`}
          onClick={() => {
            setHiddenGrid(false);
          }}
        >
          <ChevronRightIcon className="text-white m-auto" />
        </div>
        <div
          className={`min-w-[300px] w-[30vw] bg-[#091d30] h-screen p-[10px] ${
            hiddenGrid ? "hidden" : "block"
          }`}
        >
          <div className="pb-[5px] flex justify-center">
            <p className="text-white font-medium w-[90%] text-center">
              Historico
            </p>
            <p
              className="text-white font-medium text-rigth"
              onClick={() => {
                setHiddenGrid(true);
              }}
            >
              X
            </p>
          </div>
          <div className="bg-white h-[97%] mb-[16px] w-full rounded-md p-[5px]">
            <div name="formHistorico" style={{ padding: "1rem" }}>
              <label style={{ margin: 0, fontSize: "11px" }}>
                Dispositivo:
              </label>
              <div style={{ width: "100%", marginBottom: "5px" }}>
                <Select
                  options={optionDispositivo}
                  placeholder="Seleccione un dispositivo"
                  isSearchable="true"
                  isClearable="true"
                  onChange={(e) => setDispositivo(e.value)}
                />
              </div>
              <label style={{ margin: 0, fontSize: "11px" }}>
                Tipo de Historico:
              </label>
              <div style={{ width: "100%", marginBottom: "5px" }}>
                <Select
                  options={[
                    { label: "Simple", value: "simple" },
                    { label: "Por Actividades", value: "actividades" },
                  ]}
                  placeholder="Seleccione el tipo de historico"
                  isSearchable="true"
                  isClearable="true"
                  onChange={(e) => setTipoHistorico(e.value)}
                />
              </div>

              <label style={{ margin: 0, fontSize: "11px" }}>Tiempo:</label>
              <div style={{ width: "100%", marginBottom: "5px" }}>
                <Select
                  options={[
                    { label: "Ayer", value: "ayer" },
                    { label: "Hoy", value: "hoy" },
                    { label: "Semanal", value: "semanal" },
                    { label: "Personalizado", value: "ver-personalizado" },
                  ]}
                  placeholder="Seleccione el tiempo"
                  isSearchable="true"
                  isClearable="true"
                  onChange={(e) => {
                    traerHistorico(e.value);
                  }}
                />
              </div>
              {showPersonalizado && (
                <>
                  <label style={{ margin: 0, fontSize: "11px" }}>
                    Seleccion el rango:
                  </label>
                  <div
                    style={{
                      width: "100%",
                      marginBottom: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <p style={{ margin: 0, fontSize: "11px" }}>
                        Fecha Hora ini.
                      </p>
                      <input
                        style={{
                          width: "120px",
                          fontSize: "11px",
                          border: "1px solid gray",
                        }}
                        type="datetime-local"
                        onChange={(e) => {
                          //console.log(e);
                          setFechaHoraIniPersonalizada(e.target.value);
                        }}
                      />
                    </div>
                    <div>
                      <p style={{ margin: 0, fontSize: "11px" }}>
                        Fecha Hora fin
                      </p>
                      <input
                        style={{
                          width: "120px",
                          fontSize: "11px",
                          border: "1px solid gray",
                        }}
                        type="datetime-local"
                        onChange={(e) =>
                          setFechaHoraFinPersonalizada(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      traerHistorico("personalizado-con-datos");
                    }}
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 mt-[10px] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                  >
                    Aceptar
                  </button>
                </>
              )}
            </div>
            <div>
              <h1 className="text-[21px] text-center">Detalle</h1>
              <div className="p-[16px]">
                <div className="flex">
                  <p>Distancia en Km:</p>
                  <p className="ml-[10px]">{Math.trunc(distanciaTotalKm)}</p>
                </div>
                <div className="flex">
                  <p>Distancia en Metros:</p>
                  <p className="ml-[10px]">{Math.trunc(distanciaTotalM)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="gmapsCanvasContainer"
          style={{
            width: "100%",
            minHeight: "855px",
            height: "100vh",
            zIndex: 1,
          }}
        ></div>
      </div>
    </>
  );
}
