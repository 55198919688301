import { getHeaders } from "./utils";

export async function actividadesDispositivo(idDispositivo) {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/actividades/all-dispositivo/${idDispositivo}`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "GET",
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("login err");
    console.log(err);
    return [];
  }
}
export async function actividadesDispositivoEntreFecha(
  idDispositivo,
  fechaHoraIni,
  fechaHoraFin
) {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/actividades/all-dispositivo-rango-fecha`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify({ id: idDispositivo, fechaHoraIni, fechaHoraFin }),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("login err");
    console.log(err);
    return [];
  }
}
export async function actividadXId(id) {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/actividades/${id}`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "GET",
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("login err");
    console.log(err);
    return [];
  }
}

export async function actividadesEmpresa() {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/actividades/all-empresa-web`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "GET",
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("login err");
    console.log(err);
    return [];
  }
}

export async function obtenerTipoActividad() {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/actividades/tipo-actividades`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "GET",
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("login err");
    console.log(err);
    return [];
  }
}

export async function obtenerGruposActividad() {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/actividades/grupo-actividades`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "GET",
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("login err");
    console.log(err);
    return [];
  }
}

export async function obtenerSubgruposActividad(idGrupo) {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/actividades/subgrupo-actividades/${idGrupo}`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "GET",
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("login err");
    console.log(err);
    return [];
  }
}

export async function obtenerUsuarioDispositivos() {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/actividades/usuario-dispositivo`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "GET",
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("login err");
    console.log(err);
    return [];
  }
}
export async function obtenerEstadoActividad() {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/actividades/estados-actividad`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("login err");
    console.log(err);
    return [];
  }
}
export async function crearActividad(data) {
  try {
    console.log("entra a crear actividad");
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/actividades/create`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("create actividad err");
    console.log(err);
    return [];
  }
}

export async function crearTipoActividad(data) {
  try {
    console.log("entra a crear actividad");
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/actividades/create-tipo`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("create tipo actividad err");
    console.log(err);
    return [];
  }
}

export async function crearGrupoActividad(data) {
  try {
    console.log("entra a crear actividad");
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/actividades/create-grupo`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("create grupo actividad err");
    console.log(err);
    return [];
  }
}

export async function crearSubgrupoActividad(data) {
  try {
    console.log("entra a crear actividad");
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/actividades/create-subgrupo`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("create tipo actividad err");
    console.log(err);
    return [];
  }
}

export async function modificarActividad(data) {
  try {
    console.log("entra a modificar actividad");
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/actividades/update`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "PUT",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("create actividad err");
    console.log(err);
    return [];
  }
}

export async function modificarDatosServidorArchivo(data) {
  try {
    console.log("entra a modificar servidor de archivo");
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/actividades/update-servidor-archivo`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "PUT",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("modificar serv de archivo err");
    console.log(err);
    return [];
  }
}
export async function obtenerConfigServidorArchivo() {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/actividades/configuracion-servidor-archivo`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "GET",
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("list file err");
    console.log(err);
    return [];
  }
}
//body: JSON.stringify({ usuario, contracena }),
///list-file
export async function obtenerAdjuntos(data) {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/actividades/list-file`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("list file err");
    console.log(err);
    return [];
  }
}

//download-file

export async function downloadFile(data) {
  try {
    const resp = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/actividades/download-file`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.blob());
    return resp;
  } catch (err) {
    console.log("list file err");
    console.log(err);
    return [];
  }
}

export async function uploadFile(data) {
  try {
    console.log(data.file.get("photo"));
    const resp = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/actividades/upload-file-with-token/${data.codInterno}/${data.nameFile}`,
      {
        headers: {
          //"Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        method: "POST",
        body: data.file,
      }
    ).then((res) => res.text());
    return resp;
  } catch (err) {
    console.log("list file err");
    console.log(err);
    return [];
  }
}
