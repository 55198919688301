import { getHeaders } from "./utils";

export async function eventosHistoricoDispositivo(
  idDispositivo,
  fechaHoraIni,
  fechaHoraFin
) {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/historico/all-evento-dispositivo`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify({ idDispositivo, fechaHoraIni, fechaHoraFin }),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("login err");
    console.log(err);
    return [];
  }
}
