import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Rastreo from "./rastreo";
import Historico from "./historico";
import Layout from "../components/layout";
import RouterActividades from "./actividades/router";
import Configuracion from "./configuracion";
import Salir from "./salir";

const RouterPage = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Layout>
        <Route exact path={`${path}rastreo`}>
          <Rastreo />
        </Route>
        <Route exact path={`${path}historico`}>
          <Historico />
        </Route>
        <Route path={`${path}actividades`}>
          <RouterActividades />
        </Route>
        <Route path={`${path}configuracion`}>
          <Configuracion />
        </Route>
        <Route path={`${path}salir`}>
          <Salir />
        </Route>
        <Route exact path={`${path}`}>
          <Rastreo />
        </Route>
      </Layout>
    </Switch>
  );
};

export default RouterPage;
