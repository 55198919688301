import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Login from "./page/login/login";
import RouterPage from "./page/router";

const RouterMain = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route path="/">
        <RouterPage />
      </Route>
    </Switch>
  </BrowserRouter>
);

export default RouterMain;
