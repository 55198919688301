import { Fragment, useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { MarkerWithLabel } from "@googlemaps/markerwithlabel";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  obtenerEstadoActividad,
  obtenerGruposActividad,
  obtenerSubgruposActividad,
  obtenerTipoActividad,
  obtenerUsuarioDispositivos,
  actividadXId,
  modificarActividad,
} from "../../api/actividades";
import Spinner from "../../components/spinner";

export default function ModificarActividades() {
  const [loading, setLoading] = useState(false);
  const [openModalUbicacion, setOpenModalUbicacion] = useState(false);
  const [ListEstadoActividad, setListEstadoActividad] = useState([]);
  const [ListGrupoActividad, setListGrupoActividad] = useState([]);
  const [ListSubgrupoActividad, setListSubgrupoActividad] = useState([]);
  const [ListTipoActividad, setListTipoActividad] = useState([]);
  const [listUsuariosDispositivos, setListUsuariosDispositivos] = useState([]);
  const [map, setMap] = useState(null);
  const [coordenadas, setCoordenadas] = useState(null);
  const [google, setGoogle] = useState(null);
  const [dataUpdate, setDataUpdate] = useState({
    codigoInterno: null,
    idSubgrupoActividad: "",
    idGrupo: "",
    descripcion: null,
    idUsuarioDispositivo: "",
    fechaRealizar: null,
    idEstado: "",
    lat: null,
    lng: null,
    radio: null,
    observacion: null,
    idTipo: "",
    id: null,
    //fileUpload: null,
  });
  const { id } = useParams();

  const history = useHistory();

  const nextPage = (page) => {
    try {
      history.push(`${page}`);
    } catch (err) {
      console.log("nextPage err");
      console.log(err);
    }
  };
  const cancelButtonRef = useRef(null);
  const traerTipoActividad = async () => {
    const resp = await obtenerTipoActividad();
    setListTipoActividad(resp.body.body);
    console.log(resp);
  };
  const traerEstadoActividad = async () => {
    const resp = await obtenerEstadoActividad();
    setListEstadoActividad(resp.body.body);
    console.log(resp);
  };
  const traerActividad = async () => {
    const resp = await actividadXId(id);
    setDataUpdate({
      codigoInterno: resp.body.body[0].codigoInterno,
      idSubgrupoActividad: resp.body.body[0].idSubgrupoActividad,
      idGrupo: resp.body.body[0].idGrupo, //
      descripcion: resp.body.body[0].descripcion,
      idUsuarioDispositivo: resp.body.body[0].idUsuarioDispositivo,
      fechaRealizar: moment(resp.body.body[0].fechaRealizar).format(
        "YYYY-MM-DD"
      ),
      idEstado: resp.body.body[0].idEstado, //
      lat: resp.body.body[0].lat,
      lng: resp.body.body[0].lng,
      radio: resp.body.body[0].radio,
      observacion: resp.body.body[0].observacion,
      idTipo: resp.body.body[0].idTipo,
      id: resp.body.body[0].id,
      //fileUpload: null,
    });
  };
  const traerGrupoActividad = async () => {
    const resp = await obtenerGruposActividad();
    setListGrupoActividad(resp.body.body);
    console.log(resp);
  };
  const traerSubgrupoActividad = async (idGrupo) => {
    const resp = await obtenerSubgruposActividad(idGrupo);
    setListSubgrupoActividad(resp.body.body);
    console.log(resp);
  };
  const traerUsuarioDispositivo = async () => {
    const resp = await obtenerUsuarioDispositivos();
    setListUsuariosDispositivos(resp.body.body);
    console.log(resp);
  };
  const mapInit = () => {
    console.log("init");
    var boltracksrl = { lat: -17.759, lng: -63.194 };
    let mapNew = new google.maps.Map(
      document.getElementById("gmapsCanvasContainer"),
      {
        zoom: 8,
        center: boltracksrl,
        zoomControl: true,
        mapTypeControl: false,
        mapTypeControlOptions: {
          mapTypeIds: ["hybrid", "roadmap", "satellite", "terrain"],
          position: google.maps.ControlPosition.TOP_RIGHT,
        },
        streetViewControl: true,
        fullScreenControl: true,
        scrollwheel: true,
        noClear: true,
      }
    );
    mapNew.addListener("click", (mapsMouseEvent) => {
      let coord = mapsMouseEvent.latLng.toJSON();
      setCoordenadas(coord);
    });
    setMap(mapNew);
  };

  const guardarActividad = async (e) => {
    e.preventDefault();
    setLoading(true);
    const resp = await modificarActividad(dataUpdate);
    alert("se modifico correctamente");
    nextPage("/actividades");
    setLoading(false);
  };

  useEffect(() => {
    traerEstadoActividad();
    traerTipoActividad();
    traerGrupoActividad();
    traerUsuarioDispositivo();
    traerActividad();
    const script = document.createElement("script");
    script.src = "https://www.google.com/jsapi";
    script.onload = () => {
      setGoogle(window.google);
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (dataUpdate.idGrupo !== "") {
      traerSubgrupoActividad(dataUpdate.idGrupo);
    }
  }, [dataUpdate.idGrupo]);

  useEffect(() => {
    if (openModalUbicacion) {
      setTimeout(() => {
        if (google) {
          mapInit();
        }
      }, 1000);
    }
  }, [openModalUbicacion]);

  useEffect(() => {
    if (coordenadas) {
      const mark = new MarkerWithLabel({
        position: new google.maps.LatLng(coordenadas.lat, coordenadas.lng),

        clickable: true,

        map: map,
        labelContent: `<div style='color: white; background-color: #0F3457;border-radius: 5px; font-weight: 900;padding-left: 10px; padding-right: 10px;'>
          <p>Actividad</p>
           </div>`, // can also be HTMLElement
        labelAnchor: new google.maps.Point(-31, 3),
        labelClass: "labels", // the CSS class for the label
        labelStyle: { opacity: 1.0 },
      });
      setDataUpdate({
        ...dataUpdate,
        lat: coordenadas.lat,
        lng: coordenadas.lng,
      });
    }
  }, [coordenadas]);

  if (loading) {
    return (
      <div className=" w-1/2 m-auto px-[2rem] ">
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div className="space-y-6  sm:space-y-5 sm:pt-10">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Informacion de Actividad
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Cambie los datos a modificar de la actividad.
                </p>
              </div>
              <div className="h-[400px]  flex">
                <div className="m-auto">
                  <Spinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className=" w-1/2 m-auto px-[2rem] ">
        <form
          className="space-y-8 divide-y divide-gray-200"
          onSubmit={guardarActividad}
        >
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div className="space-y-6  sm:space-y-5 sm:pt-10">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Informacion de Actividad
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Cambie los datos a modificar de la actividad.
                </p>
              </div>
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px "
                  >
                    Codigo interno
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      name="last-name"
                      value={dataUpdate.codigoInterno}
                      id="last-name"
                      autoComplete="family-name"
                      className="block w-full max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      onChange={(e) => {
                        setDataUpdate({
                          ...dataUpdate,
                          codigoInterno: e.target.value,
                        });
                        console.log(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="tipo"
                    className="block text-sm font-medium text-gray-700 sm:mt-px "
                  >
                    Tipo de actividad
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      id="tipo"
                      name="tipo"
                      autoComplete="tipo-name"
                      className="block w-full max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      value={dataUpdate.idTipo}
                      //disabled={isSubmitting}
                      onChange={(e) => {
                        setDataUpdate({
                          ...dataUpdate,
                          idTipo: e.target.value,
                        });
                        console.log(e.target.value);
                      }}
                    >
                      <option value="" disabled hidden>
                        Seleccione un tipo
                      </option>
                      {ListTipoActividad.map((data) => (
                        <option value={data.id}>{data.nombre}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="grupo"
                    className="block text-sm font-medium text-gray-700 sm:mt-px "
                  >
                    Grupo
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      id="grupo"
                      name="grupo"
                      autoComplete="grupo-name"
                      className="block w-full max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      value={dataUpdate.idGrupo}
                      onChange={(e) => {
                        setDataUpdate({
                          ...dataUpdate,
                          idGrupo: e.target.value,
                        });
                        console.log(e.target.value);
                      }}
                    >
                      <option value="" disabled hidden>
                        Seleccione un Grupo
                      </option>
                      {ListGrupoActividad.map((data) => (
                        <option value={data.id}>{data.nombre}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="tipo"
                    className="block text-sm font-medium text-gray-700 sm:mt-px "
                  >
                    Subgrupo
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      id="tipo"
                      name="tipo"
                      autoComplete="tipo-name"
                      className="block w-full max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      value={dataUpdate.idSubgrupoActividad}
                      onChange={(e) => {
                        setDataUpdate({
                          ...dataUpdate,
                          idSubgrupoActividad: e.target.value,
                        });
                        console.log(e.target.value);
                      }}
                    >
                      <option value="" disabled hidden>
                        Seleccione un Subgrupo
                      </option>
                      {ListSubgrupoActividad.map((data) => (
                        <option value={data.id}>{data.nombre}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px "
                  >
                    Descripcion
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      name="last-name"
                      value={dataUpdate.descripcion}
                      id="last-name"
                      autoComplete="family-name"
                      className="block w-full max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      onChange={(e) => {
                        setDataUpdate({
                          ...dataUpdate,
                          descripcion: e.target.value,
                        });
                        console.log(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="Asignar"
                    className="block text-sm font-medium text-gray-700 sm:mt-px "
                  >
                    Asignar
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      id="Asignar"
                      name="Asignar"
                      autoComplete="Asignar-name"
                      className="block w-full max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      value={dataUpdate.idUsuarioDispositivo}
                      onChange={(e) => {
                        setDataUpdate({
                          ...dataUpdate,
                          idUsuarioDispositivo: e.target.value,
                        });
                        console.log(e.target.value);
                      }}
                    >
                      <option value="" disabled hidden>
                        Seleccione un usuario
                      </option>
                      {listUsuariosDispositivos.map((data) => (
                        <option value={data.id}>{data.nombre}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="street-address"
                    className="block text-sm font-medium text-gray-700 sm:mt-px "
                  >
                    Fecha a realizar
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="date"
                      name="street-address"
                      id="street-address"
                      autoComplete="street-address"
                      className="block w-full max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      value={moment(dataUpdate.fechaRealizar).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={(e) => {
                        setDataUpdate({
                          ...dataUpdate,
                          fechaRealizar: moment(e.target.value).format(
                            "YYYY-MM-DD"
                          ),
                        });
                        console.log(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="estado"
                    className="block text-sm font-medium text-gray-700 sm:mt-px "
                  >
                    Estado
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      id="estado"
                      name="estado"
                      autoComplete="estado-name"
                      className="block w-full max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      value={dataUpdate.idEstado}
                      onChange={(e) => {
                        setDataUpdate({
                          ...dataUpdate,
                          idEstado: e.target.value,
                        });
                        console.log(e.target.value);
                      }}
                    >
                      <option value="" disabled hidden>
                        Seleccione un Estado
                      </option>
                      {ListEstadoActividad.map((data) => (
                        <option value={data.id}>{data.nombre}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="region"
                    className="block text-sm font-medium text-gray-700 sm:mt-px "
                  >
                    Ubicacion
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0 flex">
                    <div className="flex">
                      <input
                        type="text"
                        name="region"
                        id="region"
                        placeholder="lat"
                        autoComplete="address-level1"
                        className="block w-full max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 mr-[10px] focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                        value={dataUpdate.lat}
                      />
                      <input
                        type="text"
                        name="region"
                        id="region"
                        placeholder="lng"
                        autoComplete="address-level1"
                        className="block w-full max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                        value={dataUpdate.lng}
                      />
                    </div>
                    <button
                      className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenModalUbicacion(true);
                      }}
                    >
                      Agregar
                    </button>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px "
                  >
                    Radio
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      name="last-name"
                      value={dataUpdate.radio}
                      id="last-name"
                      autoComplete="family-name"
                      className="block w-full max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      onChange={(e) => {
                        setDataUpdate({
                          ...dataUpdate,
                          radio: e.target.value,
                        });
                        console.log(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px "
                  >
                    Observacion
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      name="last-name"
                      value={dataUpdate.observacion}
                      id="last-name"
                      autoComplete="family-name"
                      className="block w-full max-w-lg rounded-md border-gray-300 border-[1px] focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                      onChange={(e) => {
                        setDataUpdate({
                          ...dataUpdate,
                          observacion: e.target.value,
                        });
                        console.log(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="button"
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>

      <Transition.Root show={openModalUbicacion} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenModalUbicacion}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl h-[80vh] w-[60vw] transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Seleccionar Ubicacion
                      </Dialog.Title>
                      <div className="mt-2">
                        <div
                          id="gmapsCanvasContainer"
                          style={{
                            width: "100%",
                            minHeight: "500px",
                            height: "100%",
                            zIndex: 1,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      onClick={() => setOpenModalUbicacion(false)}
                    >
                      Aceptar
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => setOpenModalUbicacion(false)}
                      ref={cancelButtonRef}
                    >
                      Cancelar
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
