import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Actividades from "./index";
import CrearActividades from "./crear";
import ModificarActividades from "./modificar";
import VerActividades from "./ver";
import LayoutActividades from "../../components/layout-actividades";

const RouterActividades = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <LayoutActividades>
        <Route exact path={`${path}/ver`}>
          <VerActividades />
        </Route>
        <Route path={`${path}/modificar/:id`}>
          <ModificarActividades />
        </Route>
        <Route exact path={`${path}/crear`}>
          <CrearActividades />
        </Route>
        <Route exact path={`${path}`}>
          <Actividades />
        </Route>
      </LayoutActividades>
    </Switch>
  );
};

export default RouterActividades;
