import { getHeaders } from "./utils";

export async function dispositivosEmpresa(idEmpresa) {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/dispositivo/all-empresa`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "GET",
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("login err");
    console.log(err);
    return [];
  }
}

export async function grupoDispositivosEmpresa() {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/dispositivo/grupo-empresa`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "GET",
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("login err");
    console.log(err);
    return [];
  }
}

export async function subgrupoGrupoDispositivosEmpresa(idGrupo) {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/dispositivo/subgrupos-grupo-empresa`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify({ idGrupo }),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("login err");
    console.log(err);
    return [];
  }
}

export async function crearGrupoDispositivosEmpresa(data) {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/dispositivo/create-grupo-empresa`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("login err");
    console.log(err);
    return [];
  }
}

export async function crearSubgrupoGrupoDispositivosEmpresa(data) {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/dispositivo/create-subgrupos-empresa`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("login err");
    console.log(err);
    return [];
  }
}

export async function asignarSubgrupoGrupoDispositivosEmpresa(data) {
  try {
    const { code, body } = await fetch(
      `https://gestiondeflota.boltrack.net/rastreo-movil/api/dispositivo/asignar-subgrupos-dispositivo`,
      {
        headers: getHeaders({ authenticate: true }),
        method: "PUT",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    return { code, body };
  } catch (err) {
    console.log("login err");
    console.log(err);
    return [];
  }
}
